<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">{{ $tc("mainManeuver", 1) }} {{ id }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select v-model="maneuverType" :label="this.$tc('maneuverType', 1)"
                      :items="maneuverTypeList" :disabled="readonly"
                      @change="maneuverTypeChanged()"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="traceNumber" label="Traccia" :disabled="readonly"/>
          </v-col>
          <v-col cols="3">
            <v-select v-model="terminal" :label="this.$tc('terminal', 1)"
                      :items="terminalList" item-text="description" return-object :disabled="readonly"
                      @change="terminalChanged()"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="exportTraceNumber" label="Traccia Export" :disabled="readonly || (maneuverType === 'ARRIVAL')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-datetime-picker label="ETA" v-model="etaDate" :disabled="readonly"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker label="ETP" v-model="etpDate" :disabled="readonly"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="wagonsNumber" label="Numero vagoni" :disabled="readonly"/>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="wagonsType.description" :label="this.$tc('wagonsType', 1)" :disabled="true"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field v-model="arrivalPoint.code" :label="this.$tc('arrivalPoint', 1)" :disabled="true"/>
          </v-col>
          <v-col cols="3">
            <v-autocomplete :disabled="readonly"
                v-model="maneuverParkPoint"
                :items="maneuverParkPointSelectList" :search-input.sync="maneuverParkPointSearch"
                item-text="description" item-value="code" :filter="s => {return true}" return-object
                hide-no-data hide-details clearable :label="this.$tc('maneuverParkPoint', 1)">
              <template v-slot:item="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
              <template v-slot:selection="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field v-model="regressionPoint.code" :label="this.$tc('regressionPoint', 1)" :disabled="true"/>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="locomotorEndingPoint.code" :label="this.$tc('locomotorEndingPoint', 1)" :disabled="true"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="save" :disabled="readonly">
          <v-icon>mdi-content-save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn @click="restore" :disabled="readonly">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { terminalService } from "@/services/terminal.service";
import { wagonTypeService } from "@/services/wagontype.service";
import { mainManeuverService } from "@/services/mainmaneuver.service";
import { pointService } from "@/services/point.service";
import moment from "moment";

export default {
  name: "MainManeuverEditor",
  data() {
    return {
      id: null,
      maneuverType: null,
      traceNumber: null,
      terminal: {

      },
      eta: null,
      etaDate: null,
      etp: null,
      etpDate: null,
      arrivalPoint: {},
      maneuverParkPoint: null,
      regressionPoint: {},
      locomotorEndingPoint: {

      },
      wagonsNumber: 0,
      wagonsType: {

      },
      exportTraceNumber: null,
      readonly: false,
      textFieldProps: { prependIcon: "mdi-calendar", },
      dateProps: { firstDayOfWeek: 1, },
      timeProps: { useSeconds: true, format: "24hr", },
      maneuverTypeList: [
        { text: this.$tc('arrival', 1), value: 'ARRIVAL' },
        { text: this.$tc('departure', 1), value: 'DEPARTURE' }
      ],
      terminalList: [],
      wagonTypeList: [],
      arrivalPointSelectList: [],
      arrivalPointSearch: null,
      maneuverParkPointSelectList: [],
      maneuverParkPointSearch: null,
      regressionPointSelectList: [],
      regressionPointSearch: null,
      simulationRequestId: null
    };
  },
  async beforeCreate() {
    await wagonTypeService
        .getAll()
        .then((r) => {
          this.wagonTypeList = r;
          this.terminalChanged();
        });
    await terminalService
        .getAll()
        .then((r) => {
          this.terminalList = r;
        });
  },
  created() {
    if (this.$route.params.id !== "new") {
      this.loadMainManeuver(this.$route.params.id);
    } else {
      this.simulationRequestId = this.$route.params.parentId;
      this.maneuverType = this.maneuverTypeList[0].value;
      this.maneuverTypeChanged();
    }
  },
  watch: {
    maneuverParkPointSearch (val) {
      if (!val) { return; }
      this.searchManeuverParkPointDebounced(val);
    }
  },
  methods: {
    ...mapMutations({ setSnackbarMessage: "snackbar/setSnackbarMessage" }),
    save: function () {
      let obj = {
        id: this.id,
        status: this.status,
        maneuverType: this.maneuverType,
        traceNumber: this.traceNumber,
        terminal: this.terminal,
        eta: this.dateTimeObjToString(this.etaDate),
        etp: this.getEtpToSave(),
        arrivalPoint: this.arrivalPoint,
        maneuverParkPoint: this.maneuverParkPoint,
        regressionPoint: this.regressionPoint,
        locomotorEndingPoint: this.locomotorEndingPoint,
        wagonsNumber: this.wagonsNumber,
        wagonsType: this.wagonsType,
        simulationRequestId: this.simulationRequestId,
        exportTraceNumber: this.exportTraceNumber
      };
      mainManeuverService
        .save(obj)
        .then((r) => {
          this.setSnackbarMessage({
            text: this.$tc("changesSavedSuccessfully", 1),
            color: "success",
          });
          this.loadMainManeuverObject(r);
        })
        .catch((error) => {
          console.error(error);
          this.setSnackbarMessage({
            text: this.$tc("failedToSaveChanges", 1),
            color: "error",
          });
        });
    },
    maneuverTypeChanged: function (){
      let pointCode = this.maneuverType === "ARRIVAL" ? "R1" : "R6";
      pointService.autocomplete(pointCode).then(r => {
        let point = r.find(value => value.code === pointCode);
        this.regressionPoint = point ? point : {};
      });
      let arrivalPointCode = this.maneuverType === "ARRIVAL" ? "S100" : "SE18";
      pointService.autocomplete(arrivalPointCode).then(r => {
        let point = r.find(value => value.code === arrivalPointCode);
        this.arrivalPoint = point ? point : {};
      });
      let locomotorEndingPointCode = this.maneuverType === "ARRIVAL" ? "SE18" : "S200";
      pointService.autocomplete(locomotorEndingPointCode).then(r => {
        let point = r.find(value => value.code === locomotorEndingPointCode);
        this.locomotorEndingPoint = point ? point : {};
      });
    },
    terminalChanged : function (){
      let wagonCode = this.terminal.code === "SIL" || this.terminal.code === "GET" ? "Tank" : "Intermodal";
      this.wagonsType = this.wagonTypeList.find(wagonType => wagonType.description === wagonCode);
    },
    restore: function () {
      if (this.$route.params.id !== "new") {
        this.loadMainManeuver(this.$route.params.id);
      } else {
        this.id = null;
        this.maneuverType = null;
        this.traceNumber = null;
        this.terminal = null;
        this.eta = null;
        this.etaDate = null;
        this.etp = null;
        this.etpDate = null;
        this.arrivalPoint = null;
        this.maneuverParkPoint = null;
        this.regressionPoint = null;
        this.locomotorEndingPoint = null;
        this.wagonsNumber = 0;
        this.wagonsType = null;
        this.terminalList = [];
        this.wagonTypeList = [];
        this.arrivalPointSelectList = [];
        this.arrivalPointSearch = null;
        this.maneuverParkPointSelectList = [];
        this.maneuverParkPointSearch = null;
        this.regressionPointSelectList = [];
        this.regressionPointSearch = null;
        this.locomotorEndingPointSelectList = [];
        this.locomotorEndingPointSearch = null;
        this.simulationRequestId = this.$route.params.parentId;
        this.exportTraceNumber = null;
      }
    },
    goBack: function () {
      // todo: notifica se ci sono modifiche non salvate
      this.$router.go(-1);
    },
    loadMainManeuver: function (id) {
      mainManeuverService .get(id).then((r) => {
        this.loadMainManeuverObject(r);
        this.readonly = this.$route.params.parentReadonly;
      });
    },
    loadMainManeuverObject: function(r) {
      this.id = r.id;
      this.maneuverType = r.maneuverType;
      this.traceNumber = r.traceNumber;
      this.terminal = r.terminal;
      this.eta = r.eta;
      this.etaDate = this.dateTimeStringToObject(this.eta);
      this.etp = r.etp;
      this.etpDate = this.dateTimeStringToObject(this.etp);
      this.arrivalPoint = r.arrivalPoint;
      this.maneuverParkPoint = r.maneuverParkPoint;
      this.regressionPoint = r.regressionPoint;
      this.locomotorEndingPoint = r.locomotorEndingPoint;
      this.wagonsNumber = r.wagonsNumber
      this.wagonsType = r.wagonsType;
      this.simulationRequestId = r.simulationRequestId;
      this.exportTraceNumber = r.exportTraceNumber;

      if (this.arrivalPoint)
        this.arrivalPointSelectList = [this.arrivalPoint];
      if (this.maneuverParkPoint)
        this.maneuverParkPointSelectList = [this.maneuverParkPoint];
      if (this.regressionPoint)
        this.regressionPointSelectList = [this.regressionPoint];
      if (this.locomotorEndingPoint)
        this.locomotorEndingPointSelectList = [this.locomotorEndingPoint];
    },
    mapManeuverType: function (t) {
      if (t === 'ARRIVAL') {
        return 'Arrivo';
      } else {
        return 'Partenza';
      }
    },
    searchArrivalPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.arrivalPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchManeuverParkPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.maneuverParkPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchRegressionPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.regressionPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchLocomotorEndingPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.locomotorEndingPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    getEtpToSave() {
      if (this.etp) {
        return this.dateTimeObjToString(this.etpDate);
      } else {
        if (this.maneuverType === "ARRIVAL") {
          return this.dateTimeObjToString(this.etaDate)
        } else {
          if (this.etaDate) {
            return this.dateTimeObjToString(moment(this.etaDate).subtract(20, 'minutes').toDate());
          } else {
            return null;
          }
        }
      }

    }
  },
};
</script>

<style scoped>
</style>