import moment from "moment";

export const shuntingshests = {
    generateTemplateExcel,
    generateTemplateCsv,
    joinResults,
    hasExcel,
    hasCsv,
    retrieveInputsTrainData
};

const days = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];
const SHEET_OPERATIONS_PLANS = "Operations Plans";
const HEADER_FIRST_LEVEL_COUNT = 8;

//header level 1
const HEADER_MANOVRA = "Manovra";

//header level 2
const HEADER_PRIMARIA = "Primaria";
const HEADER_SECONDARIA = "Secondaria";

//header level 3
const HEADER_ID_TRACCIA = "Id Traccia";
const HEADER_TRACCIA = "Traccia";
const HEADER_ORARIO = "Orario";
const HEADER_AP = "Arr. Part.";
const HEADER_TRENO = "Treno";
const HEADER_IMPRESA = "Impresa";
const HEADER_TERMINAL = "Terminal";

const HEADER_ORA_INIZIO = "Ora inizio";
const HEADER_ORA_FINE = "Ora fine";

const IMPORT = "I";
const EXPORT = "E";

const TERMINAL_V = "V";
const TERMINAL_VI = "VI";
const TERMINAL_VII = "VII";

function joinResults(request, inputTrainsData, outputTrainsData) {
    let x = 0;
    let pairIO = {};
    pairIO.input = inputTrainsData;
    pairIO.output = outputTrainsData.responseCsv;

    if (pairIO)
        return pairIO;

    if (!(inputTrainsData && outputTrainsData)) {
        console.error("Output or input train list is empty ");
        throw "The list of input/output trains must exist for joining to be possible";
    }
    if (inputTrainsData && outputTrainsData && outputTrainsData.length !== inputTrainsData.length) {
        console.error(
            "Output = " +
            outputTrainsData.length +
            " Input = " +
            inputTrainsData.length
        );
        throw "the number of trains in inputData is different from number of trains in outputData";
    }

    for (let i = 0; i < outputTrainsData.length; i++) {
        let inputTrain = inputTrainsData.find(t => {
            return t.id == outputTrainsData[i].id;
        });

        pairIO.push({
            input: inputTrain,
            output: outputTrainsData[i],
        });
    }

}

function generateTemplateCsv(request, IO) {
    if (IO && IO.output && ! IO.input){
        //no calculation needed because the output contains the CSV
        return IO.output;
    }
    let myStartPlanningTime = moment('' + request.startPlanningTime, "YYYY-MM-DD");
    let myEndPlanningTime = moment(request.endPlanningTime, "YYYY-MM-DD");
    let numOfPlannedDays = myEndPlanningTime.diff(myStartPlanningTime, 'days') + 1;

    let headers = [];
    headers.push("Posizione", "Traccia", "Orario", "Arr./Part.", "O/D", "Terminal");
    for (let i = 0; i < numOfPlannedDays; i++) {
        headers.push(myStartPlanningTime.clone().add(i, 'days').format("DD/MM/YYYY"));
        headers.push("Ora inizio pilotaggio", "Ora fine pilotaggio", "Ora inizio secondaria", "Ora fine secondaria");
    }
    headers.push("Tempo stazione", "Tempo parco", "Scost TW", "Binario");

    let rows = [];
    rows.push(headers.join(';'));
    for (let i = 0; i < IO.length; i++) {
        let csvRow = [];
        //console.log(outputTrainsData[i].id);
        let inputTrain = IO[i].input;
        let outputTrain = IO[i].output;

        csvRow.push(outputTrain.treno);
        csvRow.push(outputTrain.id);
        csvRow.push(moment(inputTrain.dataTraccia, moment.ISO_8601).format('DD/MM/YYYY HH:mm:ss'));
        csvRow.push(inputTrain.verso);
        csvRow.push(inputTrain.luogo);
        csvRow.push(inputTrain.terminal);

        let maneuverDate = moment(outputTrain.inizioPilotaggio, 'DD/MM/YYYY');
        for (let d = 0; d < numOfPlannedDays; d++) {
            let day = myStartPlanningTime.clone().add(d, 'days');
            if (day.isSame(maneuverDate, 'days')) {
                csvRow.push('X');
                csvRow.push(outputTrain.inizioPilotaggio);
                csvRow.push(outputTrain.finePilotaggio);
                csvRow.push(outputTrain.inizioSecondaria);
                csvRow.push(outputTrain.fineSecondaria);
            } else {
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
            }
        }

        csvRow.push(outputTrain.stazione);
        csvRow.push(outputTrain.parco);
        csvRow.push(outputTrain.scostTW);
        csvRow.push(outputTrain.binario);

        rows.push(csvRow.join(';'));
    }

    let csv = rows.join('\n');
    return csv;
}

function generateTemplateExcel(request, IO) {
    let compactedData = compactData(IO);

    let result = [];
    result.push(
        {
            "Sheet": SHEET_OPERATIONS_PLANS,
            "rows": generateSummary(compactedData)
        });

    return result;
}

function csvToArray(str, delimiter = ";") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    // return the array
    return arr;
}

function getOrario(orario) {
    return undefined;
}

function getArrivoPartenza(importExport) {
    if (importExport && "I" === importExport) {
        return "P";
    }
    return "A";
}

function getTerminal(destinazione) {
    if (TERMINAL_V === destinazione) {
        return "SAMER";
    } else if (TERMINAL_VI === destinazione) {
        return "EMT";
    } else if (TERMINAL_VII === destinazione) {
        return "TMT"
    }
    return "";
}

function isNotBlankString(input) {
    if (input && (typeof input === 'string') && input.trim().length > 0) {
        return true;
    }
    return false;
}

function getLunediPrimariaOraInizio(importExport, introduzioneParenzaneInizio, piazzamentoFascioMoli, destinazione, zonaScambiInizio, estrazioneParenzaneInizio) {
    let result = "";
    if (isNotBlankString(introduzioneParenzaneInizio)) {
        if (IMPORT === importExport) {
            if (isNotBlankString(piazzamentoFascioMoli)) {
                result = zonaScambiInizio;
            } else if (!isNotBlankString(piazzamentoFascioMoli) || destinazione === TERMINAL_VII)
                result = estrazioneParenzaneInizio;
        } else if (EXPORT === importExport) {
            result = introduzioneParenzaneInizio;
        }
    }

    if (isNotBlankString(result)) {
        return hoursMinutesSecondsFromStringDate(result);
    }
    return "";
}

function getLunediPrimariaOraFine(importExport, introduzioneParenzaneInizio, introduzioneParenzaneFine, piazzamentoFascioMoli, zonaScambiFine, estrazioneParenzaneFine) {
    let result = "";
    if (isNotBlankString(introduzioneParenzaneInizio)) {
        if (IMPORT === importExport) {
            result = introduzioneParenzaneFine;
        } else if (EXPORT === importExport) {
            if (isNotBlankString(piazzamentoFascioMoli)) {
                result = zonaScambiFine;
            } else {
                result = estrazioneParenzaneFine;
            }
        }
    }
    if (isNotBlankString(result)) {
        return hoursMinutesSecondsFromStringDate(result);
    }
    return "";
}

function getLunediSecondariaOraInizio(importExport, ingressoMoliFine, ingressoMoliInizio, piazzamentoFascioMoli, destinazione, zonaScambiSecondariaInizio, zonaScambiInizio, trasfVsMVIIInizio) {
    let result = "";
    if (isNotBlankString(ingressoMoliFine)) {
        if (IMPORT === importExport) {
            result = ingressoMoliInizio;
        } else if (EXPORT === importExport) {
            if (isNotBlankString(piazzamentoFascioMoli)) {
                if (destinazione === TERMINAL_V) {
                    result = ingressoMoliInizio;
                } else if (destinazione === TERMINAL_VI || destinazione === TERMINAL_VII) {
                    result = zonaScambiSecondariaInizio;
                }
            } else {
                if (destinazione === TERMINAL_V || destinazione === TERMINAL_VI) {
                    result = zonaScambiInizio;
                } else if (destinazione === TERMINAL_VII) {
                    result = trasfVsMVIIInizio;
                }

            }
        }
    }

    if (isNotBlankString(result)) {
        return hoursMinutesSecondsFromStringDate(result);
    }

    return "";
}

function getLunediSecondariaOraFine(importExport, ingressoMoliFine, piazzamentoFascioMoli, destinazione, zonaScambiSecondariaFine, zonaScambiFine, trasfVsMVIIFine) {
    let result = "";
    if (isNotBlankString(ingressoMoliFine)) {
        if (EXPORT === importExport) {
            result = ingressoMoliFine;
        } else if (IMPORT === importExport) {
            if (isNotBlankString(piazzamentoFascioMoli)) {
                if (TERMINAL_V === destinazione) {
                    result = ingressoMoliFine;
                } else if (TERMINAL_VI === destinazione) {
                    result = zonaScambiSecondariaFine;
                }
            } else {
                if (TERMINAL_VI === destinazione) {
                    result = zonaScambiFine
                } else if (TERMINAL_VII === destinazione) {
                    result = trasfVsMVIIFine;
                }
            }
        }
    }

    if (isNotBlankString(result)) {
        return hoursMinutesSecondsFromStringDate(result);
    }

    return "";
}

function getLunediTerminalOraInizio(dataEOraHLR) {
    if (isNotBlankString(dataEOraHLR)) {
        return getXMinuteBefore(dataEOraHLR, 10);
    }
}

function getLunediTerminalOraFine(dataEOraHLR) {
    if (isNotBlankString(dataEOraHLR)) {
        return getXMinuteBefore(dataEOraHLR, -10);
    }
}

function dateTimeObjToHourMinutesSecondsString(date) {
    if (!date) return null;
    return moment(date).format('HH:mm:ss');
}

function hoursMinutesSecondsFromStringDate(date) {
    if (!date) return null;
    let data1 = moment(dateTimeItaStringToObject(date));
    return data1.format('HH:mm:ss');
}

function getXMinuteBefore(data, minutes) {
    if (!data || !minutes) return null;
    let dataParsed = moment(dateTimeItaStringToObject(data));
    let result = dataParsed.subtract(minutes, 'minutes').format('HH:mm:ss');
    return result;
}

function dateTimeItaStringToObject(date) {
    if (!date) return null;
    return moment(date, 'DD/MM/YYYY HH:mm:ss').toDate();
}

function compactData(IO) {
    let compactData = [];
    let output = IO.output;
    output = output.replace("\r\n", "\n");
    let array = csvToArray(output);
    for (let i = 0; i < array.length; i++) {
        let csvLine = array[i];

        let lineId = parseInt(csvLine.Id);
        if (csvLine.Id && !isNaN(lineId)) {
            let id = csvLine.Id;
            let importExport = csvLine["Import/Export"];
            let destinazione = csvLine["Destinazione"];
            let dataEOraCM = csvLine["Data e ora CM"];
            let dataEOraHLR = csvLine["Data e ora HLR"];
            let estrazioneParenzaneFine = csvLine["Estraz. Parenzane fine"];
            let estrazioneParenzaneInizio = csvLine["Estraz. Parenzane inizio"];
            let ingressoMoliInizio = csvLine["Ingresso Moli inizio"];
            let introduzioneParenzaneFine = csvLine["Introduz. Parenzane fine"];
            let introduzioneParenzaneInizio = csvLine["Introduz. Parenzane inizio"];
            let piazzamentoCM = csvLine["Piazzamento CM"];
            let piazzamentoFascioMoli = csvLine["Piazzamento Fascio Moli"];
            let piazzamentoParenzane = csvLine["Piazzamento Parenzane"];
            let piazzamentoVarco = csvLine["Piazzamento Varco"];
            let trasfVsMVIIFine = csvLine["Trasf vs MVII fine"];
            let trasfVsMVIIInizio = csvLine["Trasf vs MVII inizio"];
            let zonaScambiFine = csvLine["Zona scambi fine"];
            let zonaScambiInizio = csvLine["Zona scambi inizio"];
            let zonaScambiSecondariaInizio = csvLine["Zona scambi secondaria inizio"];
            let zonaScambiSecondariaFine = csvLine["Zona scambi secondaria fine"];
            let ingressoMoliFine = csvLine["ingresso Moli fine"];

            let row = {};
            row.idTraccia = "fc6930f5-9d3e-4daa-b1ce-8c043048ec64" + id;
            row.traccia = id;
            // eslint-disable-next-line no-empty
            if (!id) {
                row.orario = "00:00:00";
            } else if (isNotBlankString(dataEOraCM)) {
                row.orario = dateTimeObjToHourMinutesSecondsString(dataEOraCM);
            } else {
                row.orario = "";
            }

            row.arrPart = getArrivoPartenza(importExport);
            row.treno = "X";
            row.impresa = "X";
            row.terminal = getTerminal(destinazione);
            row.lunedi = "X";

            let lunediValues = [];
            lunediValues["primariaOraInizio"] = getLunediPrimariaOraInizio(importExport, introduzioneParenzaneInizio, piazzamentoFascioMoli, destinazione, zonaScambiInizio, estrazioneParenzaneInizio);
            lunediValues["primariaOraFine"] = getLunediPrimariaOraFine(importExport, introduzioneParenzaneInizio, introduzioneParenzaneFine, piazzamentoFascioMoli, zonaScambiFine, estrazioneParenzaneFine);
            lunediValues["secondariaOraInizio"] = getLunediSecondariaOraInizio(importExport, ingressoMoliFine, ingressoMoliInizio, piazzamentoFascioMoli, destinazione, zonaScambiSecondariaInizio, zonaScambiInizio, trasfVsMVIIInizio);
            lunediValues["secondariaOraFine"] = getLunediSecondariaOraFine(importExport, ingressoMoliFine, piazzamentoFascioMoli, destinazione, zonaScambiSecondariaFine, zonaScambiFine, trasfVsMVIIFine);
            lunediValues["terminalOraInizio"] = getLunediTerminalOraInizio(dataEOraHLR);
            lunediValues["terminalOraFine"] = getLunediTerminalOraFine(dataEOraHLR);

            row.lunediValues = lunediValues;
            compactData.push(row);
        }
    }
    return compactData;
}


function generateFirstLevelHeaders() {
    let headers = [];

    for (let i = 0; i < HEADER_FIRST_LEVEL_COUNT; i++) {
        headers.push("");
    }

    for (let i = 0; i < days.length; i++) {
        headers.push("");
        headers.push(HEADER_MANOVRA);//to merge cells ???
        headers.push("");
    }
    return headers;
}

function generateSecondLevelHeaders() {
    let headers = [];

    for (let i = 0; i < HEADER_FIRST_LEVEL_COUNT; i++) {
        headers.push("");
    }

    for (let i = 0; i < days.length; i++) {
        headers.push("#")
        headers.push(HEADER_PRIMARIA);
        headers.push("#");
        headers.push(HEADER_SECONDARIA);//to merge cells ???
        headers.push("#");
        headers.push(HEADER_TERMINAL);
        headers.push("");
    }
    return headers;
}

function generateThirdLevelHeaders() {
    let headers = [];

    headers.push(HEADER_ID_TRACCIA);
    headers.push(HEADER_TRACCIA);
    headers.push(HEADER_ORARIO);
    headers.push(HEADER_AP);
    headers.push(HEADER_TRENO);
    headers.push(HEADER_IMPRESA);
    headers.push(HEADER_TERMINAL);


    for (let i = 0; i < days.length; i++) {
        headers.push(days[i]);
        for (let j = 0; j < 3; j++) {
            headers.push(HEADER_ORA_INIZIO);
            headers.push(HEADER_ORA_FINE);
        }
    }
    return headers;
}

function generateSummary(compactedData) {
    let rows = [];

    let firstLevelHeaders = generateFirstLevelHeaders();
    let secondLevelHeaders = generateSecondLevelHeaders();
    let thirdLevelHeaders = generateThirdLevelHeaders();

    rows.push(firstLevelHeaders);
    rows.push(secondLevelHeaders);
    rows.push(thirdLevelHeaders);

    for (let i = 0; i < compactedData.length; i++) {
        let data = compactedData[i];
        let row = [];
        row[0] = data.idTraccia;
        row[1] = data.traccia;
        row[2] = data.orario;
        row[3] = data.arrPart;
        row[4] = data.treno;
        row[5] = data.impresa;
        row[6] = data.terminal;
        row[7] = data.lunedi;

        let lunediValues = data.lunediValues;
        row[8] = lunediValues["primariaOraInizio"];
        row[9] = lunediValues["primariaOraFine"];
        row[10] = lunediValues["secondariaOraInizio"];
        row[11] = lunediValues["secondariaOraFine"];
        row[12] = lunediValues["terminalOraInizio"];
        row[13] = lunediValues["terminalOraFine"];

        rows.push(row);
    }
    return rows;
}

function hasCsv() {
    return true;
}

function hasExcel() {
    return true;
}


function retrieveInputsTrainData(request) {
    return request.rows;
}