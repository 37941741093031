import Vue from 'vue';
import Router from 'vue-router';

import LandingPage from "./components/LandingPage";
import MainPage from "./components/MainPage";
import DashboardPage from "./components/DashboardPage";
import SimulationRequestBrowser from "@/components/SimulationRequestBrowser";
import OptimizationRequestBrowser from "@/components/OptimizationRequestBrowser";
import SimulationRequestEditor from "@/components/SimulationRequestEditor";
import MissionEditor from "@/components/MissionEditor";
import MainManeuverEditor from "@/components/MainManeuverEditor";
import MissionTaskEditor from "@/components/MissionTaskEditor";
import ShuntingOptimizationEditor from "@/components/ShuntingOptimizationEditor";
import ShuntingTankOptimizationEditor from "@/components/ShuntingTankOptimizationEditor";
import LandingPageLaSpezia from "@/components/LandingPageLaSpezia";
import LandingPageTrieste from "@/components/LandingPageTrieste";
import ShuntingOptimizationTriesteEditor from "@/components/ShuntingOptimizationTriesteEditor";
import ShuntingOptimizationVadoEditor from "@/components/ShuntingOptimizationVadoEditor";
import ShuntingReschedulingSampierdarenaEditor from "@/components/ShuntingReschedulingSampierdarenaEditor";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', name: 'landing', component: LandingPage, meta: { title: '' } },
        { path: '/sp', name: 'landingLaSpezia', component: LandingPageLaSpezia, meta: { title: '' } },
        { path: '/ts', name: 'landingTrieste', component: LandingPageTrieste, meta: { title: '' } },
        { path: '/app', name: 'main', component: MainPage, meta: { title: '' },
            children : [
                { path: 'dashboard', name: 'dashboard', component: DashboardPage },
                { path: 'simulationrequest', name: 'simulationRequestBrowser', component: SimulationRequestBrowser },
                { path: 'simulationrequest/:id', name: 'simulationRequestEditor', component: SimulationRequestEditor },
                { path: 'mission/:id', name: 'missionEditor', component: MissionEditor },
                { path: 'missiontask/:id', name: 'missionTaskEditor', component: MissionTaskEditor },
                { path: 'mainmaneuver/:id', name: 'mainManeuverEditor', component: MainManeuverEditor },
                { path: 'optimizationrequest', name: 'optimizationRequestBrowser', component: OptimizationRequestBrowser },
                { path: 'shuntingoptimization/:id', name: 'shuntingOptimizationEditor', component: ShuntingOptimizationEditor },
                { path: 'shuntingtankoptimization/:id', name: 'shuntingTankOptimizationEditor', component: ShuntingTankOptimizationEditor },
                { path: 'shunting-optimization-vado/:id', name: 'shuntingOptimizationVadoEditor', component: ShuntingOptimizationVadoEditor },
                { path: 'shunting-rescheduling-sampierdarena/:id', name: 'shuntingReschedulingSampierdarenaEditor', component: ShuntingReschedulingSampierdarenaEditor },
                { path: 'shuntingoptimizationtrieste/:id', name: 'shuntingOptimizationTriesteEditor', component: ShuntingOptimizationTriesteEditor },
                { path: '/', redirect: 'dashboard' },
                { path: '*', redirect: 'dashboard' }
            ]
        },
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/sp', '/ts'];
    console.log("beforeEach: " + to.path);
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem('token');
    if (authRequired && !loggedIn) {
        if (sessionStorage.getItem('landingPage')) {
            next(sessionStorage.getItem('landingPage'));
        } else {
            next('/');
        }
    } else {
        next();
    }
})