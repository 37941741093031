<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">Shunting Scheduling Sampierdarena</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <v-text-field v-model="scenario" :readonly="readonly" :label="this.$tc('scenario', 1)"/>
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="notes" :readonly="readonly" :label="this.$tc('note', 2)"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <v-datetime-picker :label="this.$tc('startTime',1)" v-model="startPlanningTimeDate"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps"
                               v-if="!readonly"
                               :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
            <div v-show="readonly">
              {{this.$tc('startTime',1)}}
              <v-text-field readonly  :label="this.$tc('startTime',1)" v-model="readonly" v-text="dateTimeObjToString(startPlanningTimeDate)"/>
            </div>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker :label="this.$tc('endTime',1)" v-model="endPlanningTimeDate"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps"
                               v-if="!readonly"
                               :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
            <div v-show="readonly">
              {{this.$tc('endTime',1)}}
              <v-text-field readonly  :label="this.$tc('endTime',1)" v-model="readonly" v-text="dateTimeObjToString(endPlanningTimeDate)"/>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-h5">{{ $tc("shift",2) }}</v-col>
        </v-row>
        <v-row>
          <v-col class="turniDay" v-for="(day, dayNumber) in orderedCalendarRows" :key="dayNumber">
            <div>{{ $t(daysOfWeek[dayNumber]) }}</div>
            <v-card class="turno" v-for="(shift, shiftNumber) in orderedCalendarRows[dayNumber]" :key="shift.shiftCode">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <time-selector :time="shift.startTime" time-label="Inizio"
                                   @changeTime="setShiftStartTime(dayNumber, shiftNumber, $event)" :readonly="readonly"/>
                  </v-col>
                  <v-col cols="6">
                    <time-selector :time="shift.endTime" time-label="Fine"
                                   @changeTime="setShiftEndTime(dayNumber, shiftNumber, $event)" :readonly="readonly"/>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field v-model="shift.capacitaSquadre" type="number" persistent-hint label="Squadre" dense :readonly="readonly"
                                  :rules="[value => value >=0 || 'Cannot be negative']"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="shift.capacitaPiloti" type="number" persistent-hint label="Piloti" dense :readonly="readonly"
                                  :rules="[value => value >= 0 || 'Cannot be negative']"/>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" class="text-right">
                    <v-btn fab x-small @click="removeShift(dayNumber, shiftNumber)" :disabled="readonly">
                      <v-icon dense>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-btn fab x-small @click="insertShift(dayNumber)" :disabled="readonly">
                  <v-icon dense>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-h5">
            {{ $tc("initialTrainNumber", 1) }}
          </v-col>
        </v-row>
        <v-row>
          <v-card v-for="tn in initialTrainsPerTerminal" :key="tn.terminal" style="margin: 2px; width: 150px;">
            <v-card-title>{{ tn.terminal }}</v-card-title>
            <v-card-text>
              <v-text-field v-model="tn.value" type="number" persistent-hint dense :readonly="readonly" :min="tn.min" :max="tn.max" />
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="11" class="text-h5">
            {{ $tc("train",2) }}
          </v-col>
          <v-row>
            <div>
              <v-btn color="primary" fab x-small class="text-none" :loading="isSelecting" @click="onButtonClick">
                <v-icon>
                  mdi-file-plus
                </v-icon>
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".csv" @change="onFileChanged">
            </div>

            <v-btn class="ma-1 mt-0 pt-0" fab x-small color="primary" @click="createNewTrainRecordDialog = true; newTrainRecord = {};" :disabled="readonly">
              <v-icon dense>mdi-plus</v-icon>
            </v-btn>
            <v-btn class="ma-1 mt-0 pt-0" fab x-small color="primary" @click="deleteAllTrainRecordsDialog = true;" :disabled="readonly">
              <v-icon dense>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-row>
      <v-simple-table fixed-header dense >
        <template v-slot:default>
          <thead>
          <tr>
            <th >Traccia</th>
            <th>Data/Ora Traccia</th>
            <th>Terminal</th>
            <th>Verso</th>
            <th>TW Min</th>
            <th>TW Max</th>
            <th>Luogo</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
              <tr v-for="train in trains" :key="train.id">
                <td style="max-width: 120px">
                  <v-text-field readonly v-text="train.id"/>
                </td>
                <td>
                  <v-text-field readonly v-text="dateTimeObjToItaString(train.dataTraccia)"/>
                </td>
                <td>
                  <v-text-field readonly v-text="train.terminal"/>
                </td>
                <td style="max-width: 70px">
                  <v-text-field readonly v-text="train.verso"/>
                </td>
                <td>
                  <v-text-field readonly v-text="dateTimeObjToItaString(train.tw_min)"/>
                </td>
                <td>
                  <v-text-field readonly v-text="dateTimeObjToItaString(train.tw_max)"/>
                </td>
                <td>
                  <v-text-field readonly v-text="train.luogo"/>
                </td>
                <td>
                  <v-btn fab x-small class="ma-1"
                         @click.stop="deleteTrainDialogId = train.id; deleteTrainDialog = true;" :disabled="readonly">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
          <tr>

          </tr>
          </tbody>
        </template>
      </v-simple-table>

        <v-dialog v-model="deleteTrainDialog" persistent max-width="390" :retain-focus="false">
          <v-card>
            <v-card-title class="headline">{{ $tc("trainRemovingConfirmMessage", 1) }}</v-card-title>
            <v-card-text>{{ $tc("onceDeletedRecoveryNotPossibleMessage", 1) }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="deleteTrainDialog = false">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="deleteTrainDialog = false; deleteTrain(deleteTrainDialogId)">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteAllTrainRecordsDialog" persistent max-width="390" :retain-focus="false">
          <v-card>
            <v-card-title class="headline">{{ $tc("allTrainsRemovingConfirmMessage", 1) }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="deleteAllTrainRecordsDialog = false">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="deleteAllTrainRecordsDialog = false; trains = []">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="createNewTrainRecordDialog" persistent max-width="700" :retain-focus="false">
          <v-card style="padding: 20px">
            <v-row>
              <v-col cols="3">
                <v-text-field v-model="newTrainRecord.id" label="Traccia"/>
              </v-col>
              <v-col cols="3">
                <v-select
                    v-model="newTrainRecord.verso" :items="versoTypes" label="Verso"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="newTrainRecord.terminal" label="Terminal"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-datetime-picker v-model="newTrainRecord.tw_min"
                                   date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                                   :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps"
                                   label="TW MIN">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="6">
                <v-datetime-picker v-model="newTrainRecord.tw_max"
                                   date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                                   :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps"
                                   label="TW MAX">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-datetime-picker v-model="newTrainRecord.dataTraccia"
                                   date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                                   :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps" label="Data/Ora Traccia">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="newTrainRecord.luogo" label="Luogo"/>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="createNewTrainRecordDialog = false; newTrainRecord = {};">Cancel</v-btn>
              <v-tooltip top :disabled="!isNewTrainFormValid">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs"  v-on="on">
                  <v-btn color="green darken-1" :disabled="isNewTrainFormValid" text @click="createNewTrainRecordDialog = false; addNewTrain(newTrainRecord)" >Confirm</v-btn>
                  </div>
                </template>
                <span style="white-space:pre-wrap;">{{validationErrors}}</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="save" :disabled="readonly">
          <v-icon>mdi-content-save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn @click="restore">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";
import {optimizationRequestDataService} from "@/services/optimizationrequestdata.service";
import {optimizationRequestService} from "@/services/optimizationrequest.service";
import TimeSelector from "@/components/TimeSelector";

export default {
  name: "ShuntingOptimizationEditor",
  components: {TimeSelector},
  data() {

    return {
      deleteAllTrainRecordsDialog:false,
      isSelecting:false,
      newTrainRecord:{},
      createNewTrainRecordDialog: false,
      optimizationRequest:{},
      deleteTrainDialog:false,
      dataFromBody:{},
      optimizationRequestId: null,
      optimizationRequestDataId:null,
      versoTypes:[
        {text: "I", value:"I"},
        {text: "E", value: "E"}
      ],
      inputOptimizationRequestData: {
      },
      type:"INPUT",
      status:"DRAFT",
      notes: null,
      problem: "shunting",
      scenario: "Genova",
      jobId: null,
      startPlanningTimeDate: this.getYesterdayStartOfDay(),
      endPlanningTimeDate: this.getTomorrowEndOfDay(),
      calendarRows: [],
      trains: [],
      initialTrainsPerTerminal: [
        { terminal: "IMT", min: 0, max:2, value: 0},
        { terminal: "GPT", min: 0, max:1, value: 0},
        { terminal: "SIL", min: 0, max:1, value: 0},
        { terminal: "GET", min: 0, max:1, value: 0},
        { terminal: "SECH", min: 0, max:2, value: 0}
      ],
      readonly: false,
      textFieldProps: {prependIcon: "mdi-calendar",},
      dateProps: {firstDayOfWeek: 1,},
      timeProps: {useSeconds: true, format: "24hr",},
      daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    };
  },
  computed: {
    isNewTrainFormValid:function (){
      return this.validationErrors.length > 0;
    },
    validationErrors: function (){
      let errorMessages = [];
      if (this.newTrainRecord.tw_max < this.newTrainRecord.tw_min){
        errorMessages.push('TW MAX should be greater than TW MIN');
      }
      if (this.newTrainRecord.verso === 'E'){
        if (this.newTrainRecord.dataTraccia > this.newTrainRecord.tw_min) {
          errorMessages.push('The data traccia should be lower than TW MIN');
        }
      } else {
        if (this.newTrainRecord.dataTraccia < this.newTrainRecord.tw_max) {
          errorMessages.push('The data traccia should be greater than TW MAX');
        }
        if (this.newTrainRecord.id === undefined || this.newTrainRecord.id === null || this.newTrainRecord.id === ''){
          errorMessages.push(this.$tc("enterAUniqueIdMessage", 1));
        }
      }
      //console.log(errorMessages.join('\n').length);
      return errorMessages.join('\n');
    },
    orderedCalendarRows: function () {
      let out = [[], [], [], [], [], [], []];
      this.calendarRows.forEach(t => {
        if (t.weekDay !== undefined && t.weekDay !== null && t.weekDay >=0 && t.weekDay <= 6){
          out[t.weekDay].push(t);
        }
      })
      for (let i = 0; i < out.length; i++) {
        out[i] = out[i].sort((a,b) => {
          return a.startTime > b.startTime ? 1 : -1;
        });
      }
      return out;
    }
  },
  created() {
    if (this.$route.params.id !== "new") {
      this.loadShuntingOptimization(this.$route.params.id);
    } else {
      this.startPlanningTimeDate = this.getYesterdayStartOfDay();
      this.endPlanningTimeDate = this.getTomorrowEndOfDay();
      this.calendarRows = this.getDefaultCalendar();
    }
  },
  methods: {
    ...mapMutations({setSnackbarMessage: "snackbar/setSnackbarMessage"}),
    save: function () {
      try {
        this.validateCalendarRows(this.calendarRows);
        this.optimizationRequest.problem = this.problem;
        this.optimizationRequest.scenario = this.scenario;
        this.optimizationRequest.status = this.status;
        this.optimizationRequest.notes = this.notes;

        optimizationRequestService.save(this.optimizationRequest).then(response => {
          this.optimizationRequest = response;
          this.dataFromBody.rows = [...this.trains];
          this.dataFromBody.rows = [];

          this.trains.forEach(train => {
            let clone = {
              id : train.id,
              dataTraccia : this.dateTimeObjToString(train.dataTraccia),
              terminal : train.terminal,
              verso : train.verso,
              tw_min : this.dateTimeObjToString(train.tw_min),
              tw_max : this.dateTimeObjToString(train.tw_max),
              luogo : train.luogo
            }
            this.dataFromBody.rows.push(clone);
          });
          this.dataFromBody.calendarRows = [...this.calendarRows];
          this.dataFromBody.calendarRows = this.dataFromBody.calendarRows.map(shift => ({
            weekDay: shift.weekDay,
            startTime: shift.startTime,
            endTime: shift.endTime,
            capacitaSquadre: shift.capacitaSquadre,
            capacitaPiloti: shift.capacitaPiloti
          }));

        this.dataFromBody.startPlanningTime = this.dateTimeObjToString(this.startPlanningTimeDate);
        this.dataFromBody.endPlanningTime = this.dateTimeObjToString(this.endPlanningTimeDate);
        this.dataFromBody.initialTrainsPerTerminal = [];
        this.initialTrainsPerTerminal.forEach(tn => {
          this.dataFromBody.initialTrainsPerTerminal.push(tn.value);
        });

        this.inputOptimizationRequestData.data = JSON.stringify(this.dataFromBody);
        this.inputOptimizationRequestData.type = this.type;
        this.inputOptimizationRequestData.optimizationRequestId = response.id;

          optimizationRequestDataService.save(this.inputOptimizationRequestData).then((r) => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            if (this.$route.params.id === "new"){
              this.goBack();
            } else {
              this.loadShuntingOptimization(response.id);
            }
          }).catch((error) => {
                console.error(error);
                this.setSnackbarMessage({
                  text: this.$tc("failedToSaveChanges", 1),
                  color: "error",
                });
              });
        }).catch((error) => {
          console.error(error);
          this.setSnackbarMessage({
            text: this.$tc("failedToSaveChanges", 1),
            color: "error",
          });
        });
      }catch (error) {
        console.error(error);
        this.setSnackbarMessage({
          text: this.$tc("failedToSaveChanges", 1),
          color: "error",
        });
      }
    },

    addNewTrain: function(newTrainRecord){
      this.trains.push(newTrainRecord);
    },

    validateCalendarRows: function (calendarRows){
      for (const calendarRow of calendarRows) {
        if (calendarRow.capacitaSquadre < 0 || calendarRow.capacitaPiloti <0){
          throw "capacita squadre and capacita piloti cannot be negative numbers";
        }
      }
    },

    onButtonClick: function(){
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        let lines = e.target.result.split('\n');
        lines.forEach(line =>{
          let values = line.split(';');
          let train = {
            id : values[0].trim().replaceAll('"',''),
            dataTraccia : this.dateTimeItaStringToObject(values[1].trim().replaceAll('"','')),
            terminal : values[2].trim().replaceAll('"',''),
            verso : values[3].trim().replaceAll('"',''),
            tw_min : this.dateTimeItaStringToObject(values[4].trim().replaceAll('"','')),
            tw_max : this.dateTimeItaStringToObject(values[5].trim().replaceAll('"','')),
            luogo : values[6].trim().replaceAll('"','')
          };
          this.addNewTrain(train);
        });
      };
      reader.readAsText(file);
    },

    deleteTrain: function (trainId){
      this.trains = this.trains.filter(value => value.id !== trainId);
    },

    restore: function () {
      if (this.$route.params.id !== "new") {
        this.loadShuntingOptimization(this.$route.params.id);
      } else {
        this.startPlanningTimeDate = this.getYesterdayStartOfDay();
        this.endPlanningTimeDate = this.getTomorrowEndOfDay();
        this.trains = [];
        this.calendarRows = this.getDefaultCalendar();
      }
    },

    goBack: function () {
      // todo: notifica se ci sono modifiche non salvate
      this.$router.go(-1);
    },

    loadShuntingOptimization: function (optimizationRequestId) {
      optimizationRequestService.get(optimizationRequestId).then((r) => {
        this.optimizationRequest = r;
        this.optimizationRequestId = r.id;
        this.status = r.status;
        this.scenario = r.scenario;
        this.notes = r.notes;
        this.readonly = r.status !== "DRAFT";
      });
      optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, 'INPUT').then(resultList => {
        if(resultList.length < 1 ) return;
        this.inputOptimizationRequestData = resultList[0];
        if (JSON.parse(resultList[0].data)) {
          this.dataFromBody = JSON.parse(resultList[0].data);
        } else {
          this.dataFromBody = {};
        }
        this.startPlanningTimeDate = this.dateTimeStringToObject(this.dataFromBody.startPlanningTime ? this.dataFromBody.startPlanningTime : this.startPlanningTimeDate);
        this.endPlanningTimeDate = this.dateTimeStringToObject(this.dataFromBody.endPlanningTime ? this.dataFromBody.endPlanningTime : this.endPlanningTimeDate);
        this.dataFromBody.rows = this.dataFromBody.rows ? this.dataFromBody.rows : [];
        this.dataFromBody.calendarRows = this.dataFromBody.calendarRows ? this.dataFromBody.calendarRows : [];
        this.trains = this.dataFromBody.rows.map(train => (
          {
            id : train.id,
            dataTraccia : this.dateTimeStringToObject(train.dataTraccia),
            terminal : train.terminal,
            verso : train.verso,
            tw_min : this.dateTimeStringToObject(train.tw_min),
            tw_max : this.dateTimeStringToObject(train.tw_max),
            luogo : train.luogo
          }
        ));

        if (this.dataFromBody.initialTrainsPerTerminal) {
          this.dataFromBody.initialTrainsPerTerminal.forEach((n, index) => {
            if (this.initialTrainsPerTerminal[index]) {
              this.initialTrainsPerTerminal[index].value = n;
            }
          })
        }

        this.dataFromBody.calendarRows = this.dataFromBody.calendarRows.map((shift, index) => ({
          weekDay: shift.weekDay,
          startTime: shift.startTime,
          endTime: shift.endTime,
          capacitaSquadre: shift.capacitaSquadre,
          capacitaPiloti: shift.capacitaPiloti,
          shiftCode : index
        }));
        this.calendarRows = [...this.dataFromBody.calendarRows];
      });

    },

    getDefaultCalendar: function () {
      return [
        {weekDay: 0, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 0 },
        {weekDay: 0, startTime: "06:00", endTime: "12:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 1 },
        {weekDay: 0, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 2 },
        {weekDay: 0, startTime: "18:00", endTime: "00:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 3 },
        {weekDay: 1, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 4 },
        {weekDay: 1, startTime: "06:00", endTime: "12:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 5 },
        {weekDay: 1, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 6 },
        {weekDay: 1, startTime: "18:00", endTime: "00:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 7 },
        {weekDay: 2, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 8 },
        {weekDay: 2, startTime: "06:00", endTime: "12:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 9 },
        {weekDay: 2, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 10 },
        {weekDay: 2, startTime: "18:00", endTime: "00:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 11 },
        {weekDay: 3, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 12 },
        {weekDay: 3, startTime: "06:00", endTime: "12:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 13 },
        {weekDay: 3, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 14 },
        {weekDay: 3, startTime: "18:00", endTime: "00:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 15 },
        {weekDay: 4, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 16 },
        {weekDay: 4, startTime: "06:00", endTime: "12:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 17 },
        {weekDay: 4, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 18 },
        {weekDay: 4, startTime: "18:00", endTime: "00:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 19 },
        {weekDay: 5, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 20 },
        {weekDay: 5, startTime: "06:00", endTime: "12:00", capacitaSquadre: 2, capacitaPiloti: 1, shiftCode: 21 },
        {weekDay: 5, startTime: "12:00", endTime: "18:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 22 },
        {weekDay: 5, startTime: "18:00", endTime: "00:00", capacitaSquadre: 1, capacitaPiloti: 1, shiftCode: 23 },
        {weekDay: 6, startTime: "00:00", endTime: "06:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 24 },
        {weekDay: 6, startTime: "06:00", endTime: "12:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 25 },
        {weekDay: 6, startTime: "12:00", endTime: "18:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 26 },
        {weekDay: 6, startTime: "18:00", endTime: "00:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: 27 }
      ];
    },

    removeShift: function (dayNumber, shiftNumber) {
      for (let i = 0; i < this.calendarRows.length; i++) {
        if (this.calendarRows[i] === this.orderedCalendarRows[dayNumber][shiftNumber]) {
          this.calendarRows.splice(i, 1);
          break;
        }
      }
    },

    insertShift: function (dayNumber) {
      let shiftCode = 0;
      for (let i = 0; i < this.calendarRows.length; i++) {
        shiftCode = Math.max(shiftCode, this.calendarRows[i].shiftCode);
      }
      shiftCode += 1;
      this.calendarRows.push({weekDay: dayNumber, startTime: "23:59", endTime: "0:00", capacitaSquadre: 0, capacitaPiloti: 0, shiftCode: shiftCode});
    },
    setShiftStartTime: function(dayNumber, shiftNumber, startTime) {
      for (let i = 0; i < this.calendarRows.length; i++) {
        if (this.calendarRows[i] === this.orderedCalendarRows[dayNumber][shiftNumber]) {
          this.$set(this.calendarRows[i], 'startTime', startTime);
          break;
        }
      }
    },
    setShiftEndTime: function(dayNumber, shiftNumber, endTime) {
      for (let i = 0; i < this.calendarRows.length; i++) {
        if (this.calendarRows[i] === this.orderedCalendarRows[dayNumber][shiftNumber]) {
          this.$set(this.calendarRows[i], 'endTime', endTime);
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.turno {
  background-color: #b9ffe4;
  margin-top: 5px;
}
</style>