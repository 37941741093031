import moment from "moment";


export const shuntingvado = {
    generateTemplateExcel,
    generateTemplateCsv,
    joinResults,
    hasExcel,
    hasCsv,
    retrieveInputsTrainData
};


function joinResults(request, inputTrainsData, outputTrainsData) {
    let pairIO = {};
    pairIO.input = inputTrainsData;
    pairIO.output = outputTrainsData;
    return pairIO;
}

function generateTemplateCsv(request, IO) {
    if (request && IO) {
        return IO.output?.responseCsv;
    } else {
        return "";
    }
}

function generateTemplateExcel(request, IO) {
    throw Error("Not implemented");
}

function hasCsv() {
    return true;
}

function hasExcel() {
    return false;
}

function retrieveInputsTrainData(request) {
    return request.rows;
}