import {authHeader} from "@/helpers/auth-header";
import { commons } from './commons'
import {companyProblemService} from "@/services/companyproblem.service";

export const usersService = {
    login,
    logout,
    getUser,
    browseUsers,
    saveUser,
    whoAmI,
    hasRightForProblem
};

const resourceUrl = process.env.VUE_APP_USER_MANAGER_URL + `/users`;

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    };

    return fetch(process.env.VUE_APP_USER_MANAGER_URL + `/auth/`, requestOptions)
        .then(commons.handleResponse)
        .then(response => {
            if (response.token) {
                sessionStorage.setItem('token', response.token);
            }
            if (response.user) {
                let user = response.user;
                if (user?.company?.code){
                    companyProblemService.getUserRights().then(response =>{
                        user.allowedProblems = response;
                        sessionStorage.setItem('user', JSON.stringify(user));
                    });
                }
            }
            return response;
        });
}

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/${id}`, requestOptions).then(commons.handleResponse);
}

function browseUsers(offset, limit, filter) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: []
        })
    };

    return fetch(resourceUrl + `/browse`, requestOptions).then(commons.handleResponse);
}

function saveUser(user) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    let url = resourceUrl + '/';
    if (user.id) {
        url += user.id;
    }
    return fetch(url, requestOptions).then(commons.handleResponse);
}

function whoAmI() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/whoami`, requestOptions).then(commons.handleResponse);
}

function hasRightForProblem(problemCode){
    let user = JSON.parse(sessionStorage.getItem('user'));
    return (user.username === 'admin' || user?.allowedProblems?.includes(problemCode));
    //return user?.allowedProblems?.includes(problemCode);
}
