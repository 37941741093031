<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">{{ $tc("simulationRequest", 2) }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" to="/app/simulationrequest/new">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field v-model="browseFilter.id" label="ID">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
                      v-model="browseFilter.status" :items="simulationRequestStatusList"
                      clearable
                      :label="this.$tc('status',1)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker :label="this.$t('createDateFrom')" v-model="createdAtFrom"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker :label="this.$t('createDateTo')" v-model="createdAtTo"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="browse">
          <v-icon>mdi-magnify</v-icon> {{ $t("search") }}
        </v-btn>
        <v-btn @click="resetFilterAndBrowse">
          <v-icon>mdi-restore</v-icon> {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="pa-4 mb-2">
      <v-simple-table fixed-header dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $tc("status",1) }}</th>
              <th>{{ $t("createDate") }}</th>
              <th>{{ $t("updateDate") }}</th>
              <th>{{ $tc("action",2) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="simulationRequest in browseResult" :key="simulationRequest.id">
              <td>{{ simulationRequest.id }}</td>
              <td>{{ $t(simulationRequest.status) }}</td>
              <td>{{ simulationRequest.createdAt ? dateTimeObjToItaString(simulationRequest.createdAt) : "N/A" }}</td>
              <td>{{ simulationRequest.updatedAt ? dateTimeObjToItaString(simulationRequest.updatedAt) : "N/A" }}</td>
              <td>
                <v-btn fab x-small :to="'/app/simulationrequest/' + simulationRequest.id" class="ma-1" v-if="simulationRequest.status === 'DRAFT'">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
                <v-btn fab x-small :to="'/app/simulationrequest/' + simulationRequest.id" class="ma-1" v-if="simulationRequest.status !== 'DRAFT'">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn fab x-small v-if="simulationRequest.status === 'DRAFT'" class="ma-1"
                       @click.stop="submitSimulationRequestConfirmDialogId = simulationRequest.id; submitSimulationRequestConfirmDialog = true">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
                <v-btn fab x-small v-if="simulationRequest.status === 'DRAFT'" class="ma-1"
                       @click.stop="deleteSimulationRequestConfirmDialogId = simulationRequest.id; deleteSimulationRequestConfirmDialog = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="submitSimulationRequestConfirmDialog" persistent max-width="350" :retain-focus="false">
        <v-card>
          <v-card-title class="headline">Confermare l'invio della richiesta di simulazione?</v-card-title>
          <v-card-text>Una volta trasmessa non sarà più possibile modificare la richiesta di simulazione.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitSimulationRequestConfirmDialog = false">No</v-btn>
            <v-btn color="green darken-1" text @click="submitSimulationRequestConfirmDialog = false; submitSimulationRequest()">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteSimulationRequestConfirmDialog" persistent max-width="350" :retain-focus="false">
        <v-card>
          <v-card-title class="headline">Confermare la cancellazione della richiesta di simulazione?</v-card-title>
          <v-card-text>Una volta cancellata non sarà più possibile recupere la richiesta di simulazione.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteSimulationRequestConfirmDialog = false">No</v-btn>
            <v-btn color="green darken-1" text @click="deleteSimulationRequestConfirmDialog = false; deleteSimulationRequest()">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-row>
      <v-col class="text-left" cols="6">
        <span v-if="browseCount === 0">{{$tc('noResultsFound', 1)}}</span>
        <span v-else>{{$tc('resultsFrom', 1)}} {{ browseOffset + 1 }} {{$tc('to', 1)}}
          {{ Math.min(browseOffset + browseResult ? browseResult.length : 0, browseCount) }} {{$tc('outOf', 1)}}
          {{ browseCount }}</span>
      </v-col>
      <v-col cols="2" class="text-center">
        <v-select v-model="browseLimit" :items="browseLimitList" dense></v-select>
      </v-col>
      <v-col class="text-right" cols="4">
        <TableNav @offsetChanged="offsetChanged"
                  :browse-count="browseCount"
                  :browse-limit="browseLimit"
                  :browse-offset="browseOffset"></TableNav>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableNav from "./TableNav";
import {simulationRequestService} from "@/services/simulationrequest.service";
import {mainManeuverService} from "@/services/mainmaneuver.service";

export default {
  name: "SimulationRequestBrowser",
  components: { TableNav },
  data() {
    return {
      createdAtFrom: null,
      createdAtTo: null,
      browseFilter: {},
      browseResult: [],
      browseCount: 0,
      browseLimit: 10,
      browseOffset: 0,
      browseLimitList: [10, 20, 50],
      textFieldProps: {
        prependIcon: "mdi-calendar",
      },
      dateProps: {
        firstDayOfWeek: 1,
      },
      timeProps: {
        useSeconds: true,
        format: "24hr",
      },
      simulationRequestStatusList: [
        { text: this.$t("DRAFT"), value: "DRAFT" },
        { text: this.$t("PENDING"), value: "PENDING" },
        { text: this.$t("SUBMITTED"), value: "SUBMITTED" },
        { text: this.$t("CANCELLED"), value: "CANCELLED" },
      ],
      submitSimulationRequestConfirmDialog: false,
      submitSimulationRequestConfirmDialogId: null,
      deleteSimulationRequestConfirmDialog: false,
      deleteSimulationRequestConfirmDialogId: null
    };
  },
  watch: {
    browseLimit: function () {
      this.browseOffset = 0;
      this.browse();
    },
  },
  created() {
    this.browse();
  },
  methods: {
    browse: function () {
      if (this.createdAtFrom)
        this.browseFilter.createdAtFrom = this.dateTimeObjToString(this.createdAtFrom);
      if (this.createdAtTo)
        this.browseFilter.createdAtTo = this.dateTimeObjToString(this.createdAtTo);

      let sortingList = [{ column: "id", direction: "DESC"}];

      simulationRequestService
        .browse(this.browseOffset, this.browseLimit, this.browseFilter, sortingList)
        .then((r) => {
          this.browseCount = r.totalCount;
          this.browseResult = r.resultList;
        });

    },
    resetFilter() {
      this.browseFilter = {};
      this.browseOffset = 0;
      this.browseLimit = 10;
      this.createdAtFrom = null;
      this.createdAtTo = null;
    },
    resetFilterAndBrowse() {
      this.resetFilter();
      this.browse();
    },
    offsetChanged(newOffset) {
      this.browseOffset = newOffset;
      this.browse();
    },
    submitSimulationRequest() {
      simulationRequestService
          .submit(this.submitSimulationRequestConfirmDialogId)
          .then(() => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.browse();
          });
    },
    deleteSimulationRequest() {
      simulationRequestService
          .delete_(this.deleteSimulationRequestConfirmDialogId)
          .then(() => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.restore();
          });
    },
  },
};
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>