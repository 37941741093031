import moment from "moment";


export const shunting = {
    generateTemplateExcel,
    generateTemplateCsv,
    joinResults,
    hasExcel,
    hasCsv,
    retrieveInputsTrainData
};


function joinResults(request, inputTrainsData, outputTrainsData) {
    // if (outputTrainsData.length !== inputTrainsData.length) {
    //     console.error(
    //         "Output = " +
    //         outputTrainsData.length +
    //         " Input = " +
    //         inputTrainsData.length
    //     );
    //     throw "the number of trains in inputData is different from number of trains in outputData";
    // }

    let pairIO = [];

    for (let i = 0; i < outputTrainsData.length; i++) {
        let inputTrain = inputTrainsData.find(t => {
            return t.id == outputTrainsData[i].id;
        });

        pairIO.push({
            input: inputTrain,
            output: outputTrainsData[i],
        });
    }
    return pairIO;
}

function generateTemplateCsv(request, IO) {
    let myStartPlanningTime = moment('' + request.startPlanningTime, "YYYY-MM-DD");
    let myEndPlanningTime = moment(request.endPlanningTime, "YYYY-MM-DD");
    let numOfPlannedDays = myEndPlanningTime.diff(myStartPlanningTime, 'days') + 1;

    let headers = [];
    headers.push("Posizione", "Traccia", "Orario", "Arr./Part.", "O/D", "Terminal");
    for (let i = 0; i < numOfPlannedDays; i++) {
        headers.push(myStartPlanningTime.clone().add(i, 'days').format("DD/MM/YYYY"));
        headers.push("Ora inizio pilotaggio", "Ora fine pilotaggio", "Ora inizio secondaria", "Ora fine secondaria");
    }
    headers.push("Tempo stazione", "Tempo parco", "Scost TW", "Binario");

    let rows = [];
    rows.push(headers.join(';'));
    for (let i = 0; i < IO.length; i++) {
        let csvRow = [];
        //console.log(outputTrainsData[i].id);
        let inputTrain = IO[i].input;
        let outputTrain = IO[i].output;

        csvRow.push(outputTrain.treno);
        csvRow.push(outputTrain.id);
        csvRow.push(moment(inputTrain.dataTraccia, moment.ISO_8601).format('DD/MM/YYYY HH:mm:ss'));
        csvRow.push(inputTrain.verso);
        csvRow.push(inputTrain.luogo);
        csvRow.push(inputTrain.terminal);

        let maneuverDate = moment(outputTrain.inizioPilotaggio, 'DD/MM/YYYY');
        for (let d = 0; d < numOfPlannedDays; d++) {
            let day = myStartPlanningTime.clone().add(d, 'days');
            if (day.isSame(maneuverDate, 'days')) {
                csvRow.push('X');
                csvRow.push(outputTrain.inizioPilotaggio);
                csvRow.push(outputTrain.finePilotaggio);
                csvRow.push(outputTrain.inizioSecondaria);
                csvRow.push(outputTrain.fineSecondaria);
            } else {
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
                csvRow.push('');
            }
        }

        csvRow.push(outputTrain.stazione);
        csvRow.push(outputTrain.parco);
        csvRow.push(outputTrain.scostTW);
        csvRow.push(outputTrain.binario);

        rows.push(csvRow.join(';'));
    }

    let csv = rows.join('\n');
    return csv;
}

function generateTemplateExcel(request, IO) {
    throw Error("Not implemented");
}

function hasCsv() {
    return true;
}

function hasExcel() {
    return false;
}


function retrieveInputsTrainData(request) {
    return request.rows;
}