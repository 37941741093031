import { authHeader } from '@/helpers/auth-header';
import { commons } from "./commons";

const resourceUrl = process.env.VUE_APP_SHUNTING_CONNECTOR_URL + `/optimizationrequest`;

export const optimizationRequestService = {
    get,
    browse,
    save,
    submit,
    delete_,
    stats
};

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/${id}`, requestOptions)
        .then(commons.handleResponse);
}

function browse(offset, limit, filter, sortingList) {
    if (!sortingList)
        sortingList = [];
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList
        })
    };

    return fetch(resourceUrl + `/browse`, requestOptions)
        .then(commons.handleResponse);
}

function save(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };
    let url = resourceUrl + '/';
    if (obj.id) {
        url += obj.id;
    }
    return fetch(url, requestOptions)
        .then(commons.handleResponse);
}

function submit(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/${id}/submit`, requestOptions)
        .then(commons.handleResponse);
}

function delete_(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/${id}`, requestOptions)
        .then(commons.handleResponse);
}

function stats() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/stats`, requestOptions)
        .then(commons.handleResponse);
}