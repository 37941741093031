import { mediumLongPeriod } from './mediumlongperiod.template.js';
import { shunting } from './shunting.template.js';
import {shuntingshests} from "@/templates/shunting-shests.template";
import {shuntingvado} from "@/templates/shunting.template.vado";

export const models = {
    generateTemplateExcel,
    generateTemplateCsv,
    joinResults,
    hasCsv,
    hasExcel,
    retrieveInputsTrainData
};


const mappingModels = {
    'SHGEMP' : mediumLongPeriod,
    'SHGELP' : mediumLongPeriod,
    'shunting' : shunting,
    'shunting-tank': shunting,
    'SHESTS' : shuntingshests,
    'MTOTRI' : shuntingshests,
    'SHVADO' : shuntingvado,
    'DEFAULT' : shunting
}


function retrieveInputsTrainData(problem, request){
    let model = modelToCall(problem);
    return model.retrieveInputsTrainData(request);
}


function joinResults(problem, request, inputTrainsData, outputTrainsData) {
    let model = modelToCall(problem);
    return model.joinResults(request, inputTrainsData, outputTrainsData);
}

function generateTemplateExcel(problem, request, IO) {
    let model = modelToCall(problem);
    return model.generateTemplateExcel(request, IO);
}

function generateTemplateCsv(problem, request, IO) {
    let model = modelToCall(problem);
    return model.generateTemplateCsv(request, IO);
}


function hasCsv(problem){
    let model = modelToCall(problem);
    return model.hasCsv();
}

function hasExcel(problem){
    let model = modelToCall(problem);
    return model.hasExcel();
}

function modelToCall(key){
    if (!key){
        key = "DEFAULT";
    }
    let model = mappingModels[key];
    if ( model === undefined ){
        console.error("No model found for : "+ model);
        model = mappingModels['DEFAULT'];
    }
    return model;
}
