import {authHeader} from '@/helpers/auth-header';
import {commons} from "./commons";

const resourceUrl = process.env.VUE_APP_SHUNTING_CONNECTOR_URL + `/company-problem`;

export const companyProblemService = {
    getUserRights,
};

function getUserRights() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(resourceUrl + `/rights`, requestOptions)
        .then(commons.handleResponse);
}

