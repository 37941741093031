import moment from "moment";

export const mediumLongPeriod = {
    generateTemplateExcel,
    generateTemplateCsv,
    joinResults,
    hasCsv,
    hasExcel,
    retrieveInputsTrainData
};

const days = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];

const SHEET_RIEPILOGO = "Riepilogo";
const HEADER_TRACCIA = "Traccia";
//const HEADER_POSIZIONE = "Posizione";
const HEADER_GIORNO = "Giorno";
const HEADER_ORARIO = "Orario";
const HEADER_AP = "A/P";
const HEADER_TERMINAL = "Terminal";
const HEADER_LUOGO = "Luogo";
const HEADER_GIORNI_CIRC = "Giorni di Circolazione";
const HEADER_PIL_IN = "Pilotaggio Inizio";
const HEADER_PIL_FI = "Pilotaggio Fine";
const HEADER_SEC_FI = "Secondaria Fine";
const HEADER_SEC_IN = "Secondaria Inizio";

const MEDIUM_PERIOD = "MEDIUM";

const numberOfBaseHeaders = 5; // Traccia, Giorno/Orario, AP, Termina,Luogo

function joinResults(request, inputTrainsData, outputTrainsData) {

    if (outputTrainsData.length !== inputTrainsData.length && isMediuMPeriod(request)) {
        console.error(
            "Output = " +
            outputTrainsData.length +
            " Input = " +
            inputTrainsData.length
        );
        throw "the number of trains in inputData is different from number of trains in outputData";
    }

    let pairIO = [];

    for (let i = 0; i < outputTrainsData.length; i++) {       
        let inputTrain = inputTrainsData.find((t) => {
            return t.externalId == outputTrainsData[i].externalId;
        });

        pairIO.push({
            input: inputTrain,
            output: outputTrainsData[i],
        });
    }
    return pairIO;
}

function generateTemplateExcel(request, IO) {
    let isMediumPeriod = isMediuMPeriod(request);

    let compactedData = compactData(isMediumPeriod, IO);

    let result = [];
    result.push(
        {
            "Sheet": SHEET_RIEPILOGO,
            "rows": generateSummary(isMediumPeriod, compactedData)
        });
    //set single days
    for (let i = 0; i < days.length; i++) {
        let data = generateSingleDay(isMediumPeriod, days[i], compactedData);
        //Header always present
        if (data.length > 1) {
            result.push({
                "Sheet": days[i],
                "rows": data
            });
        }
    }

    return result;
}

function generateGlobalHeaders(isMediumPeriod) {
    let headers = [
        //HEADER_POSIZIONE,
        HEADER_TRACCIA
    ];

    if (isMediumPeriod) {
        headers.push(HEADER_GIORNO)
    } else {
        headers.push(HEADER_ORARIO)
    }
    headers.push(HEADER_AP);
    headers.push(HEADER_TERMINAL);
    headers.push(HEADER_LUOGO);

    if (!isMediumPeriod) {
        headers.push(HEADER_GIORNI_CIRC);
    }

    for (let i = 0; i < days.length; i++) {
        headers.push(days[i]);
        headers.push(HEADER_PIL_IN);
        headers.push(HEADER_PIL_FI);
        headers.push(HEADER_SEC_IN);
        headers.push(HEADER_SEC_FI);
    }
    return headers;
}

function generateHeaderSingleDay(isMediumPeriod, day) {
    let headers = [
        //HEADER_POSIZIONE,
        HEADER_TRACCIA
    ];

    if (isMediumPeriod) {
        headers.push(HEADER_GIORNO)
    } else {
        headers.push(HEADER_ORARIO)
    }
    headers.push(HEADER_AP);
    headers.push(HEADER_TERMINAL);
    headers.push(HEADER_LUOGO);

    if (!isMediumPeriod) {
        headers.push(HEADER_GIORNI_CIRC);
    }

    headers.push(day);
    headers.push(HEADER_PIL_IN);
    headers.push(HEADER_PIL_FI);
    headers.push(HEADER_SEC_IN);
    headers.push(HEADER_SEC_FI);
    return headers;
}

function compactData(isMediumPeriod, IO) {
    let compactData = [];
    let mapTrace = {};
    for (let i = 0; i < IO.length; i++) {
        let IOItem = IO[i];
        let input = IOItem.input;
        let output = IOItem.output;

        let result;
        if (!isMediumPeriod) {
            result = mapTrace[output.id + "_" + input.time];
        }

        if (!result) {
            result = {};
            compactData.push(result);

            //result[HEADER_POSIZIONE] = output.treno;
            result[HEADER_TRACCIA] = output.id;
            if (isMediumPeriod) {
                result[HEADER_GIORNO] = moment(input.time, moment.ISO_8601).format(
                    "DD/MM/YYYY HH:mm:ss"
                );
            } else {
                result[HEADER_ORARIO] = input.time;
                let weekday = [];

                for (let i = 0; i < input.weekDays.length; i++) {
                    if (input.weekDays[i] != '-') {
                        weekday.push(parseInt(input.weekDays[i]) + 1);
                    }
                }

                result[HEADER_GIORNI_CIRC] = weekday.join(', ');
                mapTrace[output.id + "_" + input.time] = result;
            }

            if (input.goodsCycle == 'IMPORT') {
                result[HEADER_AP] = "departure";
            } else if (input.goodsCycle == 'EXPORT') {
                result[HEADER_AP] = "arrival";
            } else {
                result[HEADER_AP] = input.goodsCycle;
            }

            result[HEADER_LUOGO] = input.origin;
            result[HEADER_TERMINAL] = input.terminal;
            for (let i = 0; i < days.length; i++) {
                result[days[i]] = "";
                result[days[i] + "_PI"] = "";
                result[days[i] + "_PF"] = "";
                result[days[i] + "_SI"] = "";
                result[days[i] + "_SF"] = "";
            }
        }
        setPiloting(result, output);
    }
    return compactData;
}

function setPiloting(result, output) {
    if (output.nonServito) {
        return;
    }
    let day = moment(output.inizioPilotaggio, "DD/MM/YYYY HH:mm");
    let whichDay = day.isoWeekday() - 1;
    result[days[whichDay]] = "X";
    result[days[whichDay] + "_PI"] = output.inizioPilotaggio;
    result[days[whichDay] + "_PF"] = output.finePilotaggio;
    result[days[whichDay] + "_SI"] = output.inizioSecondaria;
    result[days[whichDay] + "_SF"] = output.fineSecondaria;
}

function generateSummary(isMediumPeriod, compactedData) {
    let rows = [];

    let globalHeaders = generateGlobalHeaders(isMediumPeriod);

    rows.push(globalHeaders);

    for (let i = 0; i < compactedData.length; i++) {
        let data = compactedData[i];
        let row = [];
        rows.push(row);

        for (let k = 0; k < numberOfBaseHeaders; k++) {
            row.push(data[globalHeaders[k]]);
        }

        if (!isMediumPeriod) {
            row.push(data[HEADER_GIORNI_CIRC]);
        }

        for (let i = 0; i < days.length; i++) {
            row.push(data[days[i]]);
            row.push(data[days[i] + "_PI"]);
            row.push(data[days[i] + "_PF"]);
            row.push(data[days[i] + "_SI"]);
            row.push(data[days[i] + "_SF"]);
        }
    }
    return rows;
}

function generateSingleDay(isMediumPeriod, day, compactedData) {
    let rows = [];
    let header = generateHeaderSingleDay(isMediumPeriod, day);

    rows.push(header);

    for (let i = 0; i < compactedData.length; i++) {
        let data = compactedData[i];
        if (data[day] != "X") {
            continue;
        }

        let row = [];

        rows.push(row);

        for (let k = 0; k < numberOfBaseHeaders; k++) {
            row.push(data[header[k]]);
        }

        if (!isMediumPeriod) {
            row.push(data[HEADER_GIORNI_CIRC]);
        }
        row.push(data[day]);
        row.push(data[day + "_PI"]);
        row.push(data[day + "_PF"]);
        row.push(data[day + "_SI"]);
        row.push(data[day + "_SF"]);
    }
    return rows;
}

function isMediuMPeriod(request){
    return request.planningPeriod == MEDIUM_PERIOD;
}

function generateTemplateCsv(request, IO){
    throw Error("Not implemented");
}

function hasCsv(){
    return false;
}

function hasExcel(){
    return true;
}


function retrieveInputsTrainData(request){
    return request.trains;
}