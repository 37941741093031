const XLSX = require("xlsx");

export const excelGenerator = {
    generateFromArray
};

function generateFromArray(array, addFilter){
    const wb = XLSX.utils.book_new();
    //Sheet = Nome del foglio
    //rows = dati
    for(let i = 0; i < array.length; i++){
        const ws = XLSX.utils.aoa_to_sheet(array[i].rows);

        if(addFilter && array[i].rows.length > 0 ){
            var range = XLSX.utils.decode_range(ws['!ref']);
            ws['!autofilter'] = { ref: range };
        }

        ws['!cols'] = fitToColumn(array[i].rows);
        XLSX.utils.book_append_sheet(wb, ws, array[i].Sheet);
    }
    return XLSX.write(wb, { bookType: "xlsx", type: "base64" });
}


function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

