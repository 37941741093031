<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">{{ $tc("mission", 1) }} {{ id }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-datetime-picker label="Ora inizio" v-model="startDateTimeDate" :disabled="readonly"
                               date-format="dd/MM/yyyy" time-format="HH:mm:ss"
                               :text-field-props="textFieldProps" :date-picker-props="dateProps" :time-picker-props="timeProps">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="traceNumber" :label="this.$tc('traceNumber', 1)" :disabled="readonly">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
                v-model="locomotor" :disabled="readonly"
                :items="locomotorSelectList" :search-input.sync="locomotorSearch"
                item-text="description" return-object
                hide-no-data hide-details clearable :label="this.$tc('locomotor', 1)">
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="trainSplit" label="Split" :disabled="readonly">
            </v-switch>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="cutNumber" :label="this.$tc('cutNumber', 1)" :disabled="readonly">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
                v-model="locomotorStartingPoint" :disabled="readonly"
                :items="locomotorStartingPointSelectList" :search-input.sync="locomotorStartingPointSearch"
                item-text="description" item-value="code" :filter="s => {return true}" return-object
                hide-no-data hide-details clearable :label="this.$tc('locomotorStartingPoint', 1)" v-if="false">
              <template v-slot:item="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
              <template v-slot:selection="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
                v-model="trainStartingPoint" :disabled="readonly"
                :items="trainStartingPointSelectList" :search-input.sync="trainStartingPointSearch"
                item-text="description" item-value="code" :filter="s => {return true}" return-object
                hide-no-data hide-details clearable :label="this.$tc('trainStartingPoint', 1)" v-if="false">
              <template v-slot:item="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
              <template v-slot:selection="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
                v-model="trainEndingPoint" :disabled="readonly"
                :items="trainEndingPointSelectList" :search-input.sync="trainEndingPointSearch"
                item-text="description" item-value="code" :filter="s => {return true}" return-object
                hide-no-data hide-details clearable :label="this.$tc('trainEndingPoint', 1)" v-if="false">
              <template v-slot:item="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
              <template v-slot:selection="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
                v-model="wagonCutEndingPoint" :disabled="readonly"
                :items="wagonCutEndingPointSelectList" :search-input.sync="wagonCutEndingPointSearch"
                item-text="description" item-value="code" :filter="s => {return true}" return-object
                hide-no-data hide-details clearable :label="this.$tc('wagonCutEndingPoint', 1)" v-if="false">
              <template v-slot:item="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
              <template v-slot:selection="data">
                {{ data.item ? data.item.code : '' }} - {{ data.item ? data.item.description : '' }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="save" :disabled="readonly">
          <v-icon>mdi-content-save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn @click="restore" :disabled="readonly">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="8">
        <div class="text-h6 mb-4">Tasks</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn color="primary" fab x-small class="text-none" :loading="isSelecting" @click="onButtonClick" :disabled="readonly || id === null">
          <v-icon>
            mdi-file-plus
          </v-icon>
        </v-btn>
        <input ref="uploader" class="d-none" type="file" accept=".csv" @change="onFileChanged">
        <v-btn fab x-small color="primary" :disabled="readonly || id === null"
               :to="{ name: 'missionTaskEditor', params: { id: 'new', parentId: id, parentReadonly: readonly }}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-simple-table class="mb-5" fixed-header dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th width="100">{{ $tc("sequence", 1) }}</th>
            <th>{{ $tc("trainPart", 1) }}</th>
            <th>{{ $tc("departurePoint", 1) }}</th>
            <th>{{ $tc("arrivalPoint", 1) }}</th>
            <th width="100">{{ $tc("direction", 1) }}</th>
            <th>{{ $tc("couple", 1) }}</th>
            <th>{{ $tc("decouple", 1) }}</th>
            <th>Split</th>
            <th>{{ $tc("splitPart", 1) }}</th>
            <th>{{ $tc("splitPosition", 1) }}</th>
            <th>{{ $tc("splitNumber", 1) }}</th>
            <th width="200">{{ $tc("action", 2) }}</th>
          </tr>
          </thead>
          <tbody v-if="missionTaskList.length > 0">
          <tr v-for="missionTask in missionTaskList" :key="missionTask.id" >
            <td>{{ missionTask.sequence }}</td>
            <td>{{ missionTask.trainPart !== null ? missionTask.trainPart : "" }}</td>
            <td>{{ missionTask.departurePoint ? missionTask.departurePoint.description : "" }}</td>
            <td>{{ missionTask.arrivalPoint ? missionTask.arrivalPoint.description : "" }}</td>
            <td>{{ mapMissionTaskDirection(missionTask.direction) }}</td>
            <td>{{ missionTask.couple ?  $tc("yes", 1)  : $tc("no", 1) }}</td>
            <td>{{ missionTask.decouple ? $tc("yes", 1)  : $tc("no", 1) }}</td>
            <td>{{ missionTask["split"] ? $tc("yes", 1)  : $tc("no", 1) }}</td>
            <td>{{ missionTask.splitPart !== null ? missionTask.splitPart : "" }}</td>
            <td>{{ missionTask.splitPosition !== null ? mapMissionTaskSplitPosition(missionTask.splitPosition) : "" }}</td>
            <td>{{ missionTask.splitNumber !== null ? missionTask.splitNumber : "" }}</td>
            <td>
              <v-btn fab x-small class="ma-1" v-if="!readonly"
                     :to="{ name: 'missionTaskEditor', params: { id: missionTask.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
              <v-btn fab x-small class="ma-1" v-if="readonly"
                     :to="{ name: 'missionTaskEditor', params: { id: missionTask.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn fab x-small  v-if="!readonly" class="ma-1"
                     @click.stop="deleteMissionTaskDialogId = missionTask.id; deleteMissionTaskDialog = true" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="deleteMissionTaskDialog" persistent max-width="290" :retain-focus="false">
        <v-card>
          <v-card-text>{{ $tc("taskRemovingConfirmMessage", 1) }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteMissionTaskDialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="deleteMissionTaskDialog = false; deleteMissionTask()">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { missionService } from "@/services/mission.service";
import { missionTaskService } from "@/services/missionTask.service";
import {pointService} from "@/services/point.service";
import {locomotorService} from "@/services/locomotor.service";

export default {
  name: "MissionEditor",
  data() {
    return {
      deleteMissionTaskDialog: false,
      deleteMissionTaskDialogId: null,
      id: null,
      startDateTime: null,
      startDateTimeDate: null,
      locomotor:null,
      readonly: false,
      missionTaskList: [],
      traceNumber: null,
      simulationRequestId: null,
      locomotorStartingPoint: null,
      trainStartingPoint: null,
      trainEndingPoint: null,
      trainSplit: false,
      cutNumber: null,
      wagonCutEndingPoint: null,
      textFieldProps: { prependIcon: "mdi-calendar", },
      dateProps: { firstDayOfWeek: 1,},
      timeProps: { useSeconds: true, format: "24hr", },
      emptyPoint : null,
      locomotorStartingPointSelectList:[],
      locomotorStartingPointSearch:null,
      trainStartingPointSelectList:[],
      trainStartingPointSearch:null,
      trainEndingPointSelectList: [],
      trainEndingPointSearch: null,
      wagonCutEndingPointSelectList: [],
      wagonCutEndingPointSearch: null,
      locomotorSelectList: [],
      locomotorSearch: null,
      isSelecting: false,
    };
  },
  created() {
      if (this.$route.params.id !== "new") {
        this.loadMission(this.$route.params.id);
      } else {
        this.simulationRequestId = this.$route.params.parentId;
      }
  },
  watch:{
    locomotorStartingPointSearch (val){
      if (!val) { return; }
      this.searchLocomotorStartingPointDebounced(val);
    },
    trainStartingPointSearch (val){
      if (!val) { return; }
      this.searchTrainStartingPointDebounced(val);
    },
    trainEndingPointSearch (val){
      if (!val) { return; }
      this.searchTrainEndingPointDebounced(val);
    },
    wagonCutEndingPointSearch (val){
      if (!val) { return; }
      this.searchWagonCutEndingPointDebounced(val);
    },
    locomotorSearch (val){
      if (!val) { return; }
      this.searchLocomotorDebounced(val);
    }
  },
  methods: {
    ...mapMutations({ setSnackbarMessage: "snackbar/setSnackbarMessage" }),
    deleteMissionTask: function(){
      missionTaskService
          .delete_(this.deleteMissionTaskDialogId)
          .then((r) => {
          this.setSnackbarMessage({
          text: this.$tc("changesSavedSuccessfully", 1),
          color: "success",
        });
          this.restore();
      });
    },
    save: function () {
      let obj = {
        id : this.id,
        startDateTime: this.dateTimeObjToString(this.startDateTimeDate),
        locomotor : this.locomotor,
        missionTaskList : this.missionTaskList,
        traceNumber : this.traceNumber,
        locomotorStartingPoint : this.locomotorStartingPoint,
        trainStartingPoint : this.trainStartingPoint,
        trainEndingPoint : this.trainEndingPoint,
        trainSplit: this.trainSplit,
        cutNumber : this.cutNumber,
        wagonCutEndingPoint : this.wagonCutEndingPoint,
        simulationRequestId : this.simulationRequestId
      };
      missionService
        .save(obj)
        .then((r) => {
          this.setSnackbarMessage({
            text: this.$tc("changesSavedSuccessfully", 1),
            color: "success",
          });
          if (this.id === null) {
            this.$route.params.id = r.id;
            const { href } = this.$router.resolve(this.$route);
            history.replaceState({}, null, href);
          }
          this.loadMissionObject(r);
        })
        .catch((error) => {
          console.error(error);
          this.setSnackbarMessage({
            text: this.$tc("failedToSaveChanges", 1),
            color: "error",
          });
        });
    },
    restore: function () {
      if (this.$route.params.id !== "new") {
        this.loadMission(this.$route.params.id);
      } else {
        this.id = null;
        this.startDateTime = null;
        this.startDateTimeDate = null;
        this.locomotor = null;
        this.missionTaskList = [];
        this.traceNumber = null;
        this.locomotorStartingPoint = null;
        this.trainStartingPoint = null;
        this.trainEndingPoint = null;
        this.trainSplit= null;
        this.cutNumber = null;
        this.wagonCutEndingPoint = null;
        this.simulationRequestId = this.$route.params.parentId;

        this.locomotorStartingPointSelectList=[];
        this.locomotorStartingPointSearch=null;
        this.trainStartingPointSelectList=[];
        this.trainStartingPointSearch=null;
        this.trainEndingPointSelectList= [];
        this.trainEndingPointSearch= null;
        this.wagonCutEndingPointSelectList= [];
        this.wagonCutEndingPointSearch= null
      }
    },
    goBack: function () {
      // todo: notifica se ci sono modifiche non salvate
      this.$router.go(-1);
    },
    loadMission: function (id) {
      missionService.get(id).then((r) => {
        this.loadMissionObject(r);
        this.readonly = this.$route.params.parentReadonly;
      });
    },
    loadMissionObject: function (r) {

      r.missionTaskList.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0))

      this.id = r.id;
      this.startDateTime = r.startDateTime;
      this.startDateTimeDate  = this.dateTimeStringToObject(r.startDateTime);
      this.locomotor = r.locomotor;
      this.missionTaskList = r.missionTaskList;
      this.traceNumber = r.traceNumber;
      this.locomotorStartingPoint = r.locomotorStartingPoint;
      this.trainStartingPoint = r.trainStartingPoint;
      this.trainEndingPoint = r.trainEndingPoint;
      this.trainSplit= r.trainSplit;
      this.cutNumber = r.cutNumber;
      this.wagonCutEndingPoint = r.wagonCutEndingPoint;
      this.simulationRequestId = r.simulationRequestId;

      if (this.locomotorStartingPoint)
        this.locomotorStartingPointSelectList = [this.locomotorStartingPoint];
      if (this.trainStartingPoint)
        this.trainStartingPointSelectList = [this.trainStartingPoint];
      if (this.trainEndingPoint)
        this.trainEndingPointSelectList = [this.trainEndingPoint];
      if (this.wagonCutEndingPoint)
        this.wagonCutEndingPointSelectList = [this.wagonCutEndingPoint];
      if (this.locomotor)
        this.locomotorSelectList = [this.locomotor];
    },
    searchLocomotorStartingPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.locomotorStartingPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchTrainStartingPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.trainStartingPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchTrainEndingPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.trainEndingPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchWagonCutEndingPointDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        pointService.autocomplete(hint)
            .then(res => {
              this.wagonCutEndingPointSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    searchLocomotorDebounced(hint) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        locomotorService.autocomplete(hint)
            .then(res => {
              this.locomotorSelectList = res;
            });
      }, 500) /* 500ms throttle */
    },
    mapMissionTaskDirection: function (dir) {
      if (dir === 'LEFT_TO_RIGHT') {
        return 'SX -> DX';
      } else {
        return 'SX <- DX';
      }
    },
    mapMissionTaskSplitPosition: function (pos) {
      if (pos === 'HEAD') {
        return 'Testa';
      } else {
        return 'Coda';
      }
    },
    onButtonClick: function(){
      this.isSelecting = true
      window.addEventListener('focus', () => { this.isSelecting = false }, { once: true });
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async e => {
        let lines = e.target.result.split('\n');
        let tasks = [];
        for (const line of lines) {
          let values = line.split(';');

          if (values.length < 10 || values[0].trim() === 'seq')
            continue;

          let task = {
            id: null,
            mainManeuverId: null,
            trainPart: null,
            departurePoint: null,
            arrivalPoint: null,
            direction: null,
            couple: false,
            decouple: false,
            split: null,
            splitPart: null,
            splitPosition: null,
            splitNumber: null,
            missionId: this.id
          };

          let tmpSeq = values[0] ? values[0].trim().replaceAll('"', '') : null;
          task.sequence = parseInt(tmpSeq);

          let tmpTrainPart = values[1] ? values[1].trim().replaceAll('"', '') : null;
          if (tmpTrainPart)
            task.trainPart = parseInt(tmpTrainPart);


          let tmpDeparturePoint = values[2] ? values[2].trim().replaceAll('"', '') : null;
          await pointService.autocomplete(tmpDeparturePoint).then(r => {
            let point = r.find(value => value.code === tmpDeparturePoint);
            task.departurePoint = point ? point : {};
          });

          let tmpArrivalPoint = values[3] ? values[3].trim().replaceAll('"', '') : null;
          await pointService.autocomplete(tmpArrivalPoint).then(r => {
            let point = r.find(value => value.code === tmpArrivalPoint);
            task.arrivalPoint = point ? point : {};
          });

          let tmpDirection = values[4] ? values[4].trim().replaceAll('"', '') : null;
          task.direction = parseInt(tmpDirection) === 1 ? 'LEFT_TO_RIGHT' : 'RIGHT_TO_LEFT';

          task.couple = values[5] ? values[5].trim().replaceAll('"', '') === 'true' : null;
          task.decouple = values[6] ? values[6].trim().replaceAll('"', '') === 'true' : null;
          task.split = values[7] ? values[7].trim().replaceAll('"', '') === 'true' : null;

          let tmpSplitPart = values[8] ? values[8].trim().replaceAll('"', '') : null;
          if (tmpSplitPart)
            task.splitPart = parseInt(tmpSplitPart);

          let tmpSplitPosition = values[9] ? values[9].trim().replaceAll('"', '') : null;
          if (tmpSplitPosition)
            task.splitPosition = parseInt(tmpSplitPosition) === 1 ? 'HEAD' : 'TAIL';

          let tmpSplitNumber = values[10] ? values[10].trim().replaceAll('"', '') : null;
          if (tmpSplitNumber)
            task.splitNumber = parseInt(tmpSplitNumber);

          tasks.push(task);
        }

        await Promise.all(tasks.map(async (task) => {
          await missionTaskService
              .save(task)
              .then((r) => {
                this.setSnackbarMessage({
                  text: this.$tc("changesSavedSuccessfully", 1),
                  color: "success",
                });
              });
        })).then((r) => {
          this.restore();
        });

      };
      reader.readAsText(file);
    },
  },
};
</script>

<style scoped>
</style>