<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="myTime"
            persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="myTime" :label="timeLabel"
                    readonly v-bind="attrs" v-on="on" dense></v-text-field>
    </template>
    <v-time-picker v-if="modal" v-model="myTime" full-width format="24hr">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="changeTime">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "TimeSelector",
  props: [ 'time', 'timeLabel' ],
  data() {
    return {
      myTime: this.time,
      modal: false
    };
  },
  methods: {
    changeTime() {
      this.$emit('changeTime', this.myTime);
      this.$refs.dialog.save(this.myTime);
    }
  },
}
</script>

<style scoped>

</style>