<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">Shunting Optimization Trieste</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-select
                v-model="binariCampoMarzio"
                :items="binariCampoMarzioList"
                label="Binari Campo Marzio"
            ></v-select>
            <v-select
                v-model="binariFascioMoli"
                :items="binariFascioMoliList"
                label="Binari Fascio Moli"
            ></v-select>
            <v-select
                v-model="numeroLocomotori"
                :items="numeroLocomotoriList"
                label="Numero Locomotori"
            ></v-select>
            <v-select
                v-model="muro"
                :items="muroList"
                label="Muro"
            ></v-select>
            <v-select
                v-model="binariParenzane"
                :items="binariParenzaneList"
                label="Binari Parenzane"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="save" :disabled="readonly">
          <v-icon>mdi-content-save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn @click="restore">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row class = "pa-4 mb-2" >
      <v-col cols="12">
        <div class="display-1 mb-2">Combinazioni gestite</div>
      </v-col>
      <v-data-table
          :headers = "combinazioniHeaders"
          :items="combinazioniGestite"
          :hide-default-footer="true"
          class="elevation-1">
        <template v-slot:item="data">
          <tr>
            <td>{{ data.item.label }}</td>
            <td class = 'grey-background'>{{ data.item.value1 }}</td>
            <td >{{ data.item.value2 }}</td>
            <td class = 'grey-background'>{{ data.item.value3 }}</td>
            <td >{{ data.item.value4 }}</td>
            <td class = 'grey-background'>{{ data.item.value5 }}</td>
            <td >{{ data.item.value6 }}</td>
            <td class = 'grey-background'>{{ data.item.value7 }}</td>
            <td >{{ data.item.value8 }}</td>
            <td class = 'grey-background'>{{ data.item.value9 }}</td>
            <td >{{ data.item.value10 }}</td>
            <td class = 'grey-background'>{{ data.item.value11 }}</td>
            <td >{{ data.item.value12 }}</td>
            <td class = 'grey-background'>{{ data.item.value13 }}</td>
            <td >{{ data.item.value14 }}</td>
            <td class = 'grey-background'>{{ data.item.value15 }}</td>
            <td >{{ data.item.value16 }}</td>
            <td class = 'grey-background'>{{ data.item.value17 }}</td>
            <td >{{ data.item.value18 }}</td>
            <td class = 'grey-background'>{{ data.item.value19 }}</td>
            <td >{{ data.item.value20 }}</td>
            <td class = 'grey-background'>{{ data.item.value21 }}</td>
            <td >{{ data.item.value22 }}</td>
            <td class = 'grey-background'>{{ data.item.value23 }}</td>
            <td >{{ data.item.value24 }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<style>
.grey-background{ background-color: lightgrey}
</style>

<script>
import {mapMutations} from "vuex";
import {optimizationRequestDataService} from "@/services/optimizationrequestdata.service";
import {optimizationRequestService} from "@/services/optimizationrequest.service";
import TimeSelector from "@/components/TimeSelector";

export default {
  name: "ShuntingOptimizationTriesteEditor",

  data() {
    return {
      optimizationRequest: {},
      optimizationRequestId: null,
      optimizationRequestDataId: null,
      inputOptimizationRequestData: {},
      type: "INPUT",
      status: "DRAFT",
      notes: null,
      problem: 'SHESTS',
      scenario: 'Trieste',
      jobId: null,

      binariCampoMarzio: 8,
      binariFascioMoli: 8,
      numeroLocomotori: 2,
      muro: 0,
      binariParenzane: 1,

      binariCampoMarzioList: [5, 8],
      binariFascioMoliList: [8, 11],
      numeroLocomotoriList: [2, 3],
      muroList: [0, 1],
      binariParenzaneList: [1, 2],

      combinazioniHeaders : [
        {text: '', value : 'label'},
        {text: '', value : 'value1'},
        {text: '', value : 'value2'},
        {text: '', value : 'value3'},
        {text: '', value : 'value4'},
        {text: '', value : 'value5'},
        {text: '', value : 'value6'},
        {text: '', value : 'value7'},
        {text: '', value : 'value8'},
        {text: '', value : 'value9'},
        {text: '', value : 'value10'},
        {text: '', value : 'value11'},
        {text: '', value : 'value12'},
        {text: '', value : 'value13'},
        {text: '', value : 'value14'},
        {text: '', value : 'value15'},
        {text: '', value : 'value16'},
        {text: '', value : 'value17'},
        {text: '', value : 'value18'},
        {text: '', value : 'value19'},
        {text: '', value : 'value20'},
        {text: '', value : 'value21'},
        {text: '', value : 'value22'},
        {text: '', value : 'value23'},
        {text: '', value : 'value24'},
      ],
      combinazioniGestite: [
        {
          label: "Binari Campo Marzio",
          value1: "5",
          value2: "5",
          value3: "5",
          value4: "5",
          value5: "5",
          value6: "5",
          value7: "5",
          value8: "5",
          value9: "5",
          value10: "5",
          value11: "5",
          value12: "5",
          value13: "5",
          value14: "5",
          value15: "8",
          value16: "8",
          value17: "8",
          value18: "8",
          value19: "8",
          value20: "8",
          value21: "8",
          value22: "8",
          value23: "8",
          value24: "8",
        },
        {
          label: "Binari Fascio Moli",
          value1: "8",
          value2: "8",
          value3: "8",
          value4: "8",
          value5: "8",
          value6: "8",
          value7: "8",
          value8: "11",
          value9: "11",
          value10: "11",
          value11: "11",
          value12: "11",
          value13: "11",
          value14: "11",
          value15: "8",
          value16: "8",
          value17: "8",
          value18: "8",
          value19: "11",
          value20: "11",
          value21: "11",
          value22: "11",
          value23: "11",
          value24: "11",
        },
        {
          label: "Numero Locomotori",
          value1: "2",
          value2: "2",
          value3: "2",
          value4: "3",
          value5: "3",
          value6: "3",
          value7: "3",
          value8: "2",
          value9: "2",
          value10: "2",
          value11: "3",
          value12: "3",
          value13: "3",
          value14: "2",
          value15: "2",
          value16: "2",
          value17: "2",
          value18: "3",
          value19: "2",
          value20: "2",
          value21: "2",
          value22: "2",
          value23: "3",
          value24: "3",
        },
        {
          label: "Muro",
          value1: "0",
          value2: "1",
          value3: "1",
          value4: "0",
          value5: "0",
          value6: "1",
          value7: "1",
          value8: "0",
          value9: "0",
          value10: "1",
          value11: "0",
          value12: "0",
          value13: "1",
          value14: "1",
          value15: "0",
          value16: "1",
          value17: "1",
          value18: "1",
          value19: "0",
          value20: "0",
          value21: "1",
          value22: "1",
          value23: "0",
          value24: "0",
        },
        {
          label: "Binari Parenzane",
          value1: "1",
          value2: "1",
          value3: "2",
          value4: "2",
          value5: "1",
          value6: "1",
          value7: "2",
          value8: "1",
          value9: "2",
          value10: "1",
          value11: "1",
          value12: "2",
          value13: "1",
          value14: "2",
          value15: "2",
          value16: "1",
          value17: "2",
          value18: "2",
          value19: "1",
          value20: "2",
          value21: "2",
          value22: "1",
          value23: "2",
          value24: "2",
        },
      ],
      combinazioniBinariCampoMarzio: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
      combinazioniFascioMoli: [8, 8, 8, 8, 8, 8, 8, 11, 11, 11, 11, 11, 11, 11, 8, 8, 8, 8, 11, 11, 11, 11, 11, 11],
      combinazioniLocomotori: [2, 2, 2, 3, 3, 3, 3, 2, 2, 2, 3, 3, 3, 2, 2, 2, 2, 3, 2, 2, 2, 2, 3, 3],
      combinazioniMuro: [0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 1, 0, 0, 1, 1, 0, 1],
      combinazioniParenzane: [1, 1, 2, 2, 1, 1, 2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 2, 1, 2, 2],

      readonly: false,
      textFieldProps: {prependIcon: "mdi-calendar",},
      dateProps: {firstDayOfWeek: 1,},
      timeProps: {useSeconds: true, format: "24hr",},
      daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    };
  },
  computed: {
    validationErrors: function () {
      let errorMessages = [];
      return errorMessages.join('\n');
    },
  },
  created() {
    if (this.$route.params.id !== "new") {
      this.loadShuntingOptimization(this.$route.params.id);
    } else {
      //
    }
  },
  methods: {
    ...mapMutations({setSnackbarMessage: "snackbar/setSnackbarMessage"}),
    save: function () {
      try {

        this.optimizationRequest.problem = 'SHESTS';
        this.optimizationRequest.scenario = 'Trieste';
        this.optimizationRequest.status = this.status;

        optimizationRequestService.save(this.optimizationRequest).then(response => {
          this.optimizationRequest = response;

          let dataBody = {
            binariCampoMarzio: this.binariCampoMarzio,
            binariFascioMoli: this.binariFascioMoli,
            numeroLocomotori: this.numeroLocomotori,
            muro: this.muro,
            binariParenzane: this.binariParenzane,
          };

          this.inputOptimizationRequestData.data = JSON.stringify(dataBody);
          this.inputOptimizationRequestData.type = this.type;
          this.inputOptimizationRequestData.optimizationRequestId = response.id;

          optimizationRequestDataService.save(this.inputOptimizationRequestData).then((r) => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            if (this.$route.params.id === "new") {
              this.goBack();
            } else {
              this.loadShuntingOptimization(response.id);
            }
          }).catch((error) => {
            console.error(error);
            this.setSnackbarMessage({
              text: this.$tc("failedToSaveChanges", 1),
              color: "error",
            });
          });
        }).catch((error) => {
          console.error(error);
          this.setSnackbarMessage({
            text: this.$tc("failedToSaveChanges", 1),
            color: "error",
          });
        });
      } catch (error) {
        console.error(error);
        this.setSnackbarMessage({
          text: this.$tc("failedToSaveChanges", 1),
          color: "error",
        });
      }
    },

    restore: function () {
      if (this.$route.params.id !== "new") {
        this.loadShuntingOptimization(this.$route.params.id);
      } else {
        this.binariCampoMarzio = 8;
        this.binariFascioMoli = 4;
        this.numeroLocomotori = 2;
        this.muro = 0;
        this.binariParenzane = 1;
      }
    },

    goBack: function () {
      // todo: notifica se ci sono modifiche non salvate
      this.$router.go(-1);
    },

    loadShuntingOptimization: function (optimizationRequestId) {
      optimizationRequestService.get(optimizationRequestId).then((r) => {
        this.optimizationRequest = r;
        this.optimizationRequestId = r.id;
        this.status = r.status;
        this.scenario = r.scenario;
        this.notes = r.notes;
        this.readonly = r.status !== "DRAFT";
      });
      optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, 'INPUT').then(resultList => {
        if (resultList.length < 1) return;
        this.inputOptimizationRequestData = resultList[0];

        let dataBody = {}
        if (JSON.parse(resultList[0].data)) {
          dataBody = JSON.parse(resultList[0].data);
        }

        this.binariCampoMarzio = dataBody.binariCampoMarzio;
        this.binariFascioMoli = dataBody.binariFascioMoli;
        this.numeroLocomotori = dataBody.numeroLocomotori;
        this.muro = dataBody.muro;
        this.binariParenzane = dataBody.binariParenzane;
      });
    },
  },
};
</script>

<style scoped>

</style>