import { usersService } from "../services/users.service";
import { router } from "../router";

const token = sessionStorage.getItem('token');
const userString = sessionStorage.getItem('user');

const state = token
    ? { status: { loggedIn: true }, token, user: JSON.parse(userString) }
    : { status: {}, token: null, user: null };

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });
        usersService.login(username, password)
            .then(
                response => {
                    commit('loginSuccess', response);
                    try {
                        setTimeout(() => {
                            router.push('/app');
                        }, 500);

                    } catch (e) {
                        console.error(e)

                    }
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        usersService.logout();
        commit('logout');
//        router.push('/');
    }
};

const mutations = {
    loginRequest(state, token) {
        state.status = { loggingIn: true };
        state.token = token;
    },
    loginSuccess(state, response) {
        state.status = { loggedIn: true };
        state.token = response.token;
        state.user = response.user;
    },
    loginFailure(state) {
        state.status = {};
        state.token = null;
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.token = null;
        state.user = null;

        const publicPages = ['/', '/sp', '/ts'];
        const authRequired = !publicPages.includes(router.currentRoute.path);

        if (authRequired) {
            if (sessionStorage.getItem('landingPage')) {
                router.push(sessionStorage.getItem('landingPage'));
            } else {
                router.push('/');
            }
        }
    },
}

const getLandingUrl = function () {
    return sessionStorage.getItem('landingPage');
}

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
    getLandingUrl
}