<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">{{ $tc("optimizationRequest", 2) }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-tooltip bottom v-if="this.getLandingUrl() === '/' && hasRightForProblem('shunting')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                color="primary ml-2"
                to="/app/shuntingoptimization/new"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Shunting Scheduling Sampierdarena</span>
        </v-tooltip>
        <v-tooltip bottom v-if="this.getLandingUrl() === '/ts'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab color="primary ml-2" to="/app/shuntingoptimizationtrieste/new"
                   v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Shunting</span>
        </v-tooltip>
        <v-tooltip bottom v-if="this.getLandingUrl() === '/' && hasRightForProblem('shunting-tank')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                color="secondary ml-2"
                to="/app/shuntingtankoptimization/new"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Shunting Scheduling Tank Sampierdarena</span>
        </v-tooltip>
        <v-tooltip bottom v-if="this.getLandingUrl() === '/' && hasRightForProblem('shunting-resc') ">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                color="#Ffb000"
                class="ml-2"
                to="/app/shunting-rescheduling-sampierdarena/new"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Shunting Re-Scheduling Sampierdarena</span>
        </v-tooltip>
        <v-tooltip bottom
                   v-if="this.getLandingUrl() === '/' && this.username != 'shunt11' && this.username != 'fuorimuro_t' && hasRightForProblem('SHVADO')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                color="tertiary ml-2"
                to="/app/shunting-optimization-vado/new"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Shunting Savona-Vado</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field v-model="browseFilter.id" label="ID"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="browseFilter.status"
                :items="optimizationRequestStatusList"
                clearable
                :label="this.$tc('status', 1)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="browseFilter.problem"
                :label="this.$tc('problem', 1)"
            >
            </v-text-field>
          </v-col
          >
          <v-col cols="3">
            <v-text-field
                v-model="browseFilter.scenario"
                :label="this.$tc('scenario', 1)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker
                :label="this.$t('createDateFrom')"
                v-model="createdAtFrom"
                date-format="dd/MM/yyyy"
                time-format="HH:mm:ss"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="3">
            <v-datetime-picker
                :label="this.$t('createDateTo')"
                v-model="createdAtTo"
                date-format="dd/MM/yyyy"
                time-format="HH:mm:ss"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="browse">
          <v-icon>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
        <v-btn @click="resetFilterAndBrowse">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
        <v-checkbox
            class="ml-2"
            v-model="refreshEnabled"
            label="Aggiorna automaticamente"
        ></v-checkbox>
      </v-card-actions>
    </v-card>
    <v-card class="pa-4 mb-2">
      <v-simple-table fixed-header dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th>ID</th>
            <th>{{ $tc("status", 1) }}</th>
            <th>{{ $tc("note", 2) }}</th>
            <th>{{ $tc("problem", 1) }}</th>
            <th>{{ $tc("scenario", 1) }}</th>
            <th>{{ $tc("jobId", 1) }}</th>
            <th>{{ $tc("createDate", 1) }}</th>
            <th>{{ $tc("action", 2) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="optimizationRequest in browseResult"
              :key="optimizationRequest.id"
          >
            <td>{{ optimizationRequest.id }}</td>
            <td>{{ $t(optimizationRequest.status) }}</td>
            <td>{{ optimizationRequest.notes }}</td>
            <td>{{ optimizationRequest.problem }}</td>
            <td>{{ optimizationRequest.scenario }}</td>
            <td>{{ optimizationRequest.jobId }}</td>
            <td>
              {{ dateTimeObjToItaString(optimizationRequest.createdAt) }}
            </td>
            <td>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT' && optimizationRequest.problem === 'shunting' && optimizationRequest.scenario === 'Genova'"
                     :to="'/app/shuntingoptimization/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-pen</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT' && optimizationRequest.problem === 'SHVADO' && optimizationRequest.scenario === 'Vado'"
                     :to="'/app/shunting-optimization-vado/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-pen</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT' && optimizationRequest.problem === 'shunting-resc' && optimizationRequest.scenario === 'Genova'"
                     :to="'/app/shunting-rescheduling-sampierdarena/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-pen</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status !== 'DRAFT' && optimizationRequest.problem === 'shunting'"
                     :to="'/app/shuntingoptimization/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                  </template>
                  <span>Vedi</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status !== 'DRAFT' && optimizationRequest.problem === 'shunting-resc'"
                     :to="'/app/shunting-rescheduling-sampierdarena/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                  </template>
                  <span>Vedi</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status !== 'DRAFT' && optimizationRequest.problem === 'SHVADO'"
                     :to="'/app/shunting-optimization-vado/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                  </template>
                  <span>Vedi</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT' && optimizationRequest.problem === 'shunting-tank'"
                     :to="'/app/shuntingtankoptimization/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-pen</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT' && optimizationRequest.problem === 'SHESTS'"
                     :to="'/app/shuntingoptimizationtrieste/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-pen</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status !== 'DRAFT' && optimizationRequest.problem === 'shunting-tank'"
                     :to="'/app/shuntingtankoptimization/' + optimizationRequest.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                  </template>
                  <span>Vedi</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT'"
                     @click.stop="submitOptimizationRequestConfirmDialogId = optimizationRequest.id; submitOptimizationRequestConfirmDialog = true;"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-send</v-icon>
                  </template>
                  <span>Invia</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1" style="background-color: #8bc34a; color: white"
                     v-if="optimizationRequest.status === 'COMPLETED' && hasExcel(optimizationRequest.problem)"
                     @click.stop="
                    optimizationRequestOutputExcel(
                      optimizationRequest.problem,
                      optimizationRequest.id
                    )
                  "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                  </template>
                  <span>Scarica Excel</span>
                </v-tooltip>
              </v-btn>
              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'COMPLETED' && hasCsv(optimizationRequest.problem)"
                     @click.stop="optimizationRequestOutputCsv(optimizationRequest.problem, optimizationRequest.id)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                    >mdi-briefcase-download
                    </v-icon
                    >
                  </template>
                  <span>Scarica CSV</span>
                </v-tooltip>
              </v-btn>

              <v-btn fab x-small class="ma-1"
                     v-if="isDownloadJSONButtonVisible(optimizationRequest)"
                     @click.stop="downloadOutputData(optimizationRequest.id, 'OUTPUT')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-download</v-icon>
                  </template>
                  <span>Scarica JSON output</span>
                </v-tooltip>
              </v-btn>

              <v-btn fab x-small class="ma-1"
                     v-if="isDownloadJSONButtonVisible(optimizationRequest)"
                     @click.stop="downloadInputData(optimizationRequest.id, 'INPUT')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-download-box</v-icon>
                  </template>
                  <span>Scarica JSON input</span>
                </v-tooltip>
              </v-btn>

              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'ERROR'"
                     @click.stop="downloadOutputData(optimizationRequest.id, 'OUTPUT')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-download-box</v-icon>
                  </template>
                  <span>Scarica error dettaglio</span>
                </v-tooltip>
              </v-btn>

              <v-btn fab x-small class="ma-1"
                     v-if="optimizationRequest.status === 'DRAFT'"
                     @click.stop="deleteOptimizationRequestConfirmDialogId = optimizationRequest.id; deleteOptimizationRequestConfirmDialog = true;"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-close</v-icon>
                  </template>
                  <span>Cancella</span>
                </v-tooltip>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog
          v-model="submitOptimizationRequestConfirmDialog"
          persistent
          max-width="350"
          :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">{{
              this.$tc("confirmSendOptimizationRequestHeader")
            }}
          </v-card-title>
          <v-card-text>{{
              this.$tc("confirmSendOptimizationRequestBody")
            }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="submitOptimizationRequestConfirmDialog = false"
            >{{ this.$tc("no") }}
            </v-btn
            >
            <v-btn
                color="green darken-1"
                text
                @click="
                submitOptimizationRequestConfirmDialog = false;
                submitOptimizationRequest();
              "
            >{{ this.$tc("yes") }}
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="deleteOptimizationRequestConfirmDialog"
          persistent
          max-width="350"
          :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">{{
              this.$tc("confirmCancelOptimizationRequestHeader")
            }}
          </v-card-title>
          <v-card-text>{{
              this.$tc("confirmCancelOptimizationRequestBody")
            }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="deleteOptimizationRequestConfirmDialog = false"
            >{{ this.$tc("no") }}
            </v-btn
            >
            <v-btn
                color="green darken-1"
                text
                @click="
                deleteOptimizationRequestConfirmDialog = false;
                deleteOptimizationRequest();
              "
            >{{ this.$tc("yes") }}
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-row>
      <v-col class="text-left" cols="6">
        <span v-if="browseCount === 0">{{ $tc("noResultsFound", 1) }}</span>
        <span v-else
        >{{ $tc("resultsFrom", 1) }} {{ browseOffset + 1 }}
          {{ $tc("to", 1) }}
          {{
            Math.min(
                browseOffset + browseResult ? browseResult.length : 0,
                browseCount
            )
          }}
          {{ $tc("outOf", 1) }} {{ browseCount }}</span
        >
      </v-col>
      <v-col cols="2" class="text-center">
        <v-select
            v-model="browseLimit"
            :items="browseLimitList"
            dense
        ></v-select>
      </v-col>
      <v-col class="text-right" cols="4">
        <TableNav
            @offsetChanged="offsetChanged"
            :browse-count="browseCount"
            :browse-limit="browseLimit"
            :browse-offset="browseOffset"
        ></TableNav>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableNav from "./TableNav";
import {optimizationRequestService} from "@/services/optimizationrequest.service";
import {mapMutations} from "vuex";
import {optimizationRequestDataService} from "@/services/optimizationrequestdata.service";
import {excelGenerator} from "@/services/excel.service";
import {models} from "@/templates/models.template";
import {usersService} from "@/services/users.service";

export default {
  name: "OptimizationRequestBrowser",
  components: {TableNav},
  data() {
    return {
      deleteOptimizationRequestConfirmDialogId: null,
      submitOptimizationRequestConfirmDialogId: null,
      submitOptimizationRequestConfirmDialog: false,
      deleteOptimizationRequestConfirmDialog: false,
      createdAtFrom: null,
      createdAtTo: null,
      browseFilter: {},
      browseResult: [],
      browseCount: 0,
      browseLimit: 10,
      browseOffset: 0,
      browseLimitList: [10, 20, 50],
      textFieldProps: {
        prependIcon: "mdi-calendar",
      },
      dateProps: {
        firstDayOfWeek: 1,
      },
      timeProps: {
        useSeconds: true,
        format: "24hr",
      },
      optimizationRequestStatusList: [
        {text: this.$t("DRAFT"), value: "DRAFT"},
        {text: this.$t("PENDING"), value: "PENDING"},
        {text: this.$t("SENT"), value: "SENT"},
        {text: this.$t("COMPLETED"), value: "COMPLETED"},
        {text: this.$t("ERROR"), value: "ERROR"},
        {text: this.$t("CANCELED"), value: "CANCELLED"},
      ],
      refreshEnabled: true,
      refreshTimer: null,
      username: '',
    };
  },
  watch: {
    browseLimit: function () {
      this.browseOffset = 0;
      this.browse();
    },
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.username = user.username;
  },
  mounted() {
    this.browse();
    this.timer = setInterval(this.browseRefresh, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    hasRightForProblem(problemCode){
      return usersService.hasRightForProblem(problemCode);
    },

    ...mapMutations({setSnackbarMessage: "snackbar/setSnackbarMessage"}),
    browseRefresh() {
      if (this.refreshEnabled) {
        this.browse();
      }
    },
    isDownloadJSONButtonVisible(optimizationRequest) {
      if (optimizationRequest.status != 'COMPLETED'
          || optimizationRequest.problem === 'shunting'
          || optimizationRequest.problem === 'shunting-resc'
          || optimizationRequest.problem === 'shunting-tank'
          || optimizationRequest.problem === 'SHVADO') {
        return false;
      }
      return true;
    },

    browse: function () {
      this.browseFilter.createdAtFrom = this.dateTimeObjToString(
          this.createdAtFrom
      );
      this.browseFilter.createdAtTo = this.dateTimeObjToString(
          this.createdAtTo
      );

      let sortingList = [{column: "id", direction: "DESC"}];

      optimizationRequestService
          .browse(
              this.browseOffset,
              this.browseLimit,
              this.browseFilter,
              sortingList
          )
          .then((r) => {
            this.browseCount = r.totalCount;
            this.browseResult = r.resultList;
          });
    },
    recoverRequestAndResponse: async function (problem, optimizationRequestId) {
      let outputTrainsData = {};
      let inputTrainsData = {};
      let request = null;

      let input = optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, "INPUT")
          .then((resultList) => {
            if (resultList.length > 0) {
              request = JSON.parse(resultList[0].data);
              inputTrainsData = models.retrieveInputsTrainData(problem, request);
            }
          });

      let output = optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, "OUTPUT")
          .then((resultList) => {
            if (resultList.length > 0) {
              outputTrainsData = JSON.parse(resultList[0].data);
            }
          });

      await input;
      await output;

      let pairIO = models.joinResults(
          problem,
          request,
          inputTrainsData,
          outputTrainsData
      );

      return {IO: pairIO, request: request};
    },
    optimizationRequestOutputCsv: async function (
        problem,
        optimizationRequestId
    ) {
      let callback = await this.recoverRequestAndResponse(
          problem,
          optimizationRequestId
      );

      let csv = models.generateTemplateCsv(problem, callback.request, callback.IO);

      /*
      // colonna unica
      for (let i = 0; i < outputTrainsData.length; i++) {
        let csvRow = {
          "Posizione": outputTrainsData[i].id,
          "Traccia": outputTrainsData[i].treno,
          "Orario":inputTrainsData[i].dataTraccia,
          "Arr./Part.":inputTrainsData[i].verso,
          "O/D":inputTrainsData[i].luogo,
          "Terminal":inputTrainsData[i].terminal,
          "Ora inizio pilotaggio":outputTrainsData[i].inizioPilotaggio,
          "Ora fine pilotaggio":outputTrainsData[i].finePilotaggio,
          "Tempo Stazione":outputTrainsData[i].inizioSecondaria,
          "Tempo Parco":outputTrainsData[i].fineSecondaria,
          "Scostamento TW":outputTrainsData[i].stazione,
          "Binario suggerito":outputTrainsData[i].parco,
          "Scost TW":outputTrainsData[i].scostTW,
          "Binario":outputTrainsData[i].binario,
        };
        rows.push(csvRow);

      }
      let fields = Object.keys(rows[0]);
      let replacer = function(key, value) { return value === null ? '' : value };
      let csv = rows.map(function(row){
        return fields.map(function(fieldName){
          return JSON.stringify(row[fieldName], replacer)
        }).join(';')
      });
      csv.unshift(fields.join(';'));
      csv = csv.join('\r\n');
       */

      this.downloadFile(
          csv,
          "outputForOptimizationRequestID_" +
          optimizationRequestId +
          "_Date_" +
          this.dateTimeObjToString(Date.now()) +
          ".csv",
          "text/csv;charset=utf-8;"
      );
    },

    submitOptimizationRequest() {
      optimizationRequestService
          .submit(this.submitOptimizationRequestConfirmDialogId)
          .then(() => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.browse();
          });
    },
    deleteOptimizationRequest() {
      optimizationRequestService
          .delete_(this.deleteOptimizationRequestConfirmDialogId)
          .then(() => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.browse();
          });
    },
    resetFilter() {
      this.browseFilter = {};
      this.browseOffset = 0;
      this.browseLimit = 10;
      this.createdAtFrom = null;
      this.createdAtTo = null;
    },
    resetFilterAndBrowse() {
      this.resetFilter();
      this.browse();
    },
    offsetChanged(newOffset) {
      this.browseOffset = newOffset;
      this.browse();
    },

    downloadOutputData: function (optimizationRequestId, type) {
      let outputOptimizationRequestData;
      optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, type)
          .then((resultList) => {
            if (resultList.length < 1) return;
            outputOptimizationRequestData = resultList[0];
            this.downloadFile(
                outputOptimizationRequestData.data,
                "outputDataForOptimizationRequestID_" +
                optimizationRequestId +
                "_Date_" +
                this.dateTimeObjToString(Date.now()),
                "text/plain"
            );
          });
    },
    downloadInputData: function (optimizationRequestId, type) {
      let inputOptimizationRequestData;
      optimizationRequestDataService
          .searchByRequestIdAndType(optimizationRequestId, type)
          .then((resultList) => {
            if (resultList.length < 1) return;
            inputOptimizationRequestData = resultList[0];
            this.downloadFile(
                inputOptimizationRequestData.data,
                "inputDataForOptimizationRequestID_" +
                optimizationRequestId +
                "_Date_" +
                this.dateTimeObjToString(Date.now()),
                "text/plain"
            );
          });
    },
    downloadFile: function (data, fileName, fileType) {
      let blob = new Blob([data], {type: fileType});

      let a = document.createElement("a");
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function () {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },
    getLandingUrl: function () {
      return sessionStorage.getItem("landingPage");
    },
    optimizationRequestOutputExcel: async function (
        problem,
        optimizationRequestId
    ) {
      let callback = await this.recoverRequestAndResponse(
          problem,
          optimizationRequestId
      );

      let generateTemplate = models.generateTemplateExcel(
          problem,
          callback.request,
          callback.IO
      );

      let data = excelGenerator.generateFromArray(generateTemplate, true);

      this.downloadB64(
          data,
          "outputDataForOptimizationRequestID_" +
          optimizationRequestId +
          "_Date_" +
          this.dateTimeObjToString(Date.now()) +
          ".xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    downloadB64(b64Data, filename, type) {
      var element = document.createElement("a");
      element.setAttribute("href", "data:" + type + ";base64," + b64Data);
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    hasExcel(problem) {
      return models.hasExcel(problem);
    },
    hasCsv(problem) {
      return models.hasCsv(problem);
    },
  },
};
</script>

<style scoped>
</style>