<template>
  <v-container fluid>
    <v-row align="center" justify="space-around">

    </v-row>
    <v-row class="justify-space-around">
      <SimulationRequestsWidget v-if="this.getLandingUrl() === '/' && hasRightForProblem('DTGESP')"></SimulationRequestsWidget>
      <OptimizationRequestsWidget></OptimizationRequestsWidget>
    </v-row>
  </v-container>
</template>

<script>

import SimulationRequestsWidget from "@/components/widgets/SimulationRequestsWidget";
import OptimizationRequestsWidget from "@/components/widgets/OptimizationRequestsWidget";
import {usersService} from "@/services/users.service";

export default {
  name: "DashboardPage",
  components: {OptimizationRequestsWidget, SimulationRequestsWidget},
  methods: {
    getLandingUrl: function () {
      return sessionStorage.getItem('landingPage');
    },

    hasRightForProblem(problemCode){
      return usersService.hasRightForProblem(problemCode);
    },
  }
}

</script>

<style scoped>

</style>