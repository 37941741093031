<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <div class="display-2 mb-4">{{ $tc("simulationRequest", 1) }} {{ id }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select
                v-model="status" :items="simulationRequestStatusList"
                :label="this.$tc('status',1)"
                readonly
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="save" :disabled="readonly">
          <v-icon>mdi-content-save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn @click="restore" :disabled="readonly">
          <v-icon>mdi-restore</v-icon>
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="8">
        <div class="text-h6 mb-4">{{ $tc("mainManeuver", 2) }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary"  :disabled="readonly || (id === null)"
               :to="{ name: 'mainManeuverEditor', params: { id: 'new', parentId: id, parentReadonly: readonly }}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-simple-table class="mb-5" fixed-header dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th width="100">ID</th>
            <th>{{ $tc("type", 1) }}</th>
            <th>{{ $tc("traceNumber", 1) }}</th>
            <th>Terminal</th>
            <th>ETA</th>
            <th>ETP</th>
            <th width="200">{{ $tc("action", 2) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="maneuver in mainManeuverList" :key="maneuver.id">
            <td>{{ maneuver.id }}</td>
            <td>{{ $tc(maneuver.maneuverType, 1) }}</td>
            <td>{{ maneuver.traceNumber }}</td>
            <td>{{ maneuver.terminal ? maneuver.terminal.description : '' }}</td>
            <td>{{ dateTimeObjToItaString(maneuver.eta) }}</td>
            <td>{{ dateTimeObjToItaString(maneuver.etp) }}</td>
            <td>
              <v-btn fab x-small class="ma-1" v-if="!readonly"
                     :to="{ name: 'mainManeuverEditor', params: { id: maneuver.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
              <v-btn fab x-small class="ma-1" v-if="readonly"
                     :to="{ name: 'mainManeuverEditor', params: { id: maneuver.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn fab x-small class="ma-1" v-if="!readonly"
                     @click.stop="deleteManeuverDialogId = maneuver.id; deleteManeuverDialog = true">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="deleteManeuverDialog" persistent max-width="290" :retain-focus="false">
        <v-card>
          <v-card-text>{{ $tc("mainManeuverRemovingConfirmMessage", 1) }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteManeuverDialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="deleteManeuverDialog = false; deleteMainManeuver()">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-row>
      <v-col cols="8">
        <div class="text-h6 mb-4">{{ $tc("mission", 2) }}</div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn fab color="primary"  :disabled="readonly || (id === null)"
               :to="{ name: 'missionEditor', params: { id: 'new', parentId: id, parentReadonly: readonly }}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4 mb-2">
      <v-simple-table class="mb-5" fixed-header dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th width="100">ID</th>
            <th>{{ $tc("startTime", 1) }}</th>
            <th>Locomotore</th>
            <th>{{ $tc("traceNumber", 1) }}</th>
            <th>Split</th>
            <th>{{ $tc("cutNumber", 1) }}</th>
            <th width="200">{{ $tc("action", 2) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mission in missionList" :key="mission.id">
            <td>{{ mission.id }}</td>
            <td>{{ dateTimeObjToItaString(mission.startDateTime) }}</td>
            <td>{{ mission.locomotor ? mission.locomotor.description : ""}}</td>
            <td>{{ mission.traceNumber }}</td>
            <td>{{ mission.trainSplit === true ?  $tc("yes", 1)  : $tc("no", 1)}}</td>
            <td>{{ mission.cutNumber }}</td>
            <td>
              <v-btn fab x-small class="ma-1" v-if="!readonly"
                     :to="{ name: 'missionEditor', params: { id: mission.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
              <v-btn fab x-small class="ma-1" v-if="readonly"
                     :to="{ name: 'missionEditor', params: { id: mission.id, parentId: id, parentReadonly: readonly }}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn fab x-small class="ma-1"  v-if="!readonly"
                     @click.stop="deleteMissionDialogId = mission.id; deleteMissionDialog = true">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="deleteMissionDialog" persistent max-width="290" :retain-focus="false">
        <v-card>
          <v-card-text>{{ $tc("missionRemovingConfirmMessage", 1) }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteMissionDialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="deleteMissionDialog = false; deleteMission()">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { simulationRequestService } from "@/services/simulationrequest.service";
import {missionService} from "@/services/mission.service";
import { mainManeuverService } from "@/services/mainmaneuver.service";

export default {
  name: "SimulationRequestEditor",
  data() {
    return {
      deleteManeuverDialog : false,
      deleteManeuverDialogId: null,
      deleteMissionDialog: false,
      deleteMissionDialogId: null,
      id: null,
      status: "",
      readonly: false,
      mainManeuverList: [],
      missionList: [],
      textFieldProps: {
        prependIcon: "mdi-calendar",
      },
      dateProps: {
        firstDayOfWeek: 1,
      },
      timeProps: {
        useSeconds: true,
        format: "24hr",
      },
      simulationRequestStatusList: [
        { text: this.$t("DRAFT"), value: "DRAFT" },
        { text: this.$t("PENDING"), value: "PENDING" },
        { text: this.$t("SUBMITTED"), value: "SUBMITTED" },
        { text: this.$t("CANCELLED"), value: "CANCELLED" },
      ]
    };
  },
  created() {
    if (this.$route.params.id !== "new") {
      this.loadSimulationRequest(this.$route.params.id);
    } else {
      this.status = "DRAFT";
    }
  },
  methods: {
    deleteMission: function(){
      missionService
          .delete_(this.deleteMissionDialogId)
          .then((r) => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.restore();
          });
    },
    deleteMainManeuver: function(){
      mainManeuverService
          .delete_(this.deleteManeuverDialogId)
          .then(() => {
            this.setSnackbarMessage({
              text: this.$tc("changesSavedSuccessfully", 1),
              color: "success",
            });
            this.restore();
          });
    },
    ...mapMutations({ setSnackbarMessage: "snackbar/setSnackbarMessage" }),
    save: function () {
      let obj = {
        id: this.id,
        status: this.status
      };
      simulationRequestService
        .save(obj)
        .then((r) => {
          this.setSnackbarMessage({
            text: this.$tc("changesSavedSuccessfully", 1),
            color: "success",
          });
          if (this.id === null) {
            this.$route.params.id = r.id;
            const { href } = this.$router.resolve(this.$route);
            history.replaceState({}, null, href);
          }
          this.loadSimulationRequestObject(r);

        })
        .catch((error) => {
          console.error(error);
          this.setSnackbarMessage({
            text: this.$tc("failedToSaveChanges", 1),
            color: "error",
          });
        });
    },
    restore: function () {
      if (this.$route.params.id !== "new") {
        this.loadSimulationRequest(this.$route.params.id);
      } else {
        this.id = null;
        this.status = null;
        this.mainManeuverList = [];
        this.missionList = [];
      }
    },
    goBack: function () {
      // todo: notifica se ci sono modifiche non salvate
      this.$router.go(-1);
    },
    loadSimulationRequest: function (id) {
      simulationRequestService.get(id).then((r) => {
        this.loadSimulationRequestObject(r);
      });
    },
    loadSimulationRequestObject: function (r) {
      this.id = r.id;
      this.status = r.status;
      this.mainManeuverList = r.mainManeuverList;
      this.missionList = r.missionList;
      this.readonly = r.status !== 'DRAFT';
    },
  },
  mapManeuverType: function (t) {
    if (t === 'ARRIVAL') {
      return 'Arrivo';
    } else {
      return 'Partenza';
    }
  }
};
</script>

<style scoped>
</style>