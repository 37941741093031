<template>
  <v-card width="300" class="ma-5">
    <v-card-text class="text-center">
      <v-avatar color="primary" size="84">
        <span class="white--text headline">{{ total }}</span>
      </v-avatar>
      <v-container fluid>
        <v-row align="center" justify="space-around">
          <v-tooltip bottom v-for="item in actualStatus" v-bind:key="item.label">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar :color="item.color" size="32" :title="item.label"
                        v-bind="attrs" v-on="on">
                <span class="white--text">{{ item.value }}</span>
              </v-avatar>
            </template>
            <span>{{ item.label }}</span>
          </v-tooltip>
        </v-row>
      </v-container>
      <v-btn to="/app/simulationrequests">{{ $tc("simulationRequest", 2) }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {simulationRequestService} from "@/services/simulationrequest.service";

export default {
  name: "SimulationRequestsWidget",
  data() {
    return {
      total: 0,
      status: [
        {key: 'DRAFT', label: 'Bozza', value: 0, color: '#898989'},
        {key: 'PENDING', label: 'In attesa', value: 0, color: '#ffe100'},
        {key: 'SUBMITTED', label: 'Inviata', value: 0, color: '#65bb4d'},
        {key: 'CANCELED', label: 'Annullata', value: 0, color: '#555555'},
      ]
    }
  },
  computed: {
    actualStatus: function () {
      return this.status.filter(s => s.value > 0);
    }
  },
  created() {
    this.loadStats();
  },
  methods: {
    loadStats: function () {
      simulationRequestService.stats()
          .then(r => {
            if (r.statsDataList && r.statsDataList.length > 0) {
              r.statsDataList.forEach(s => {
                this.total += s.value;
                let statusFiltered = this.status.filter(st => st.key === s.label);
                if (statusFiltered.length > 0)
                  statusFiltered[0].value = s.value;
              });
            }
          })
    }
  }
}

</script>

<style scoped>

</style>