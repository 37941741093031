import { render, staticRenderFns } from "./SimulationRequestsWidget.vue?vue&type=template&id=d8080814&scoped=true&"
import script from "./SimulationRequestsWidget.vue?vue&type=script&lang=js&"
export * from "./SimulationRequestsWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8080814",
  null
  
)

export default component.exports